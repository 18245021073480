.reportIssue {
    background-color: #fff;
    padding: 24px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.reportIssueHeading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reportIssueHeading h1 svg{
    margin-right: 15px;
    cursor: pointer;
}

.reportIssueHeading h1 {
    font-family: 'MyCustomFont';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 20px;
}

.reportIssueHeading p {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}
.reportIssueHeading button{
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: rgba(52, 64, 84, 1);
    background: rgb(255, 255, 255);
    padding: 4px 14px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.reportIssueHeading .open{
    background-color: #fff !important;
    color: #027a48 !important;
    border: 1px solid #027a48 !important;
}
.reportIssueHeading .closed{
    border: 1px solid rgba(240, 68, 56, 1) !important;
    color: rgba(240, 68, 56, 1) !important;
    background-color: #fff !important;
}

.reportBoxList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.reportBoxList .reportBox {
    width: 49%;
    min-height: 154px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.reportBoxList .reportBox p {
    margin-top: 20px;
    font-family: 'MyCustomFont';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
}

.reportDetails {
    display: flex;
}

.reportTag h1 {
    font-family: 'MyCustomFont';
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.reportTag .reportTagBox {
    background: rgba(244, 247, 248, 1);
    min-height: 154px;
    min-width: 300px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.reportTag .reportTagBox p{
    margin-top: 20px;
  font-family: 'MyCustomFont';
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}
.reportInfo{
    margin-left: 32px;
}

.reportInfo p {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
margin-bottom: 0px;
}

.reportInfoSide{
    display: flex;
    justify-content: space-between;
}

.reportInfoSide .dropdown-btn{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    border: 1px solid rgba(208, 213, 221, 1);
    padding: 10px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.reportInfoSide .dropdown-btn .tableImage p{
    margin-bottom: 0px;
}

.issueImages{
    display: flex;
    justify-content: left;
    margin-top: 10px;
}
.issueFileShow{
    position: relative;
   display: flex;
   justify-content: center;
}
.issueFileShow .fileFormat{
    margin-right: 10px;
    position: relative;
    width: 105px;
    height: 105px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.issueFileShow .fileFormat img{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.issueFileShow .fileDelete{
    position: absolute;
    bottom: 10px;
    text-align: center;
}
.issueDescription textarea,.issueDescription textarea:focus-visible{
    width: 100%;
    padding: 10px;
    min-height: 150px;
    border: 1px solid rgba(208, 213, 221, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    outline: none !important;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.submitIssue button,.submitIssue button:hover{
    margin-top: 24px;
    background: #6366F1;
    color: #fff;
    box-shadow: 0px 2.4000000953674316px 6px 0px #0619380F;
    padding: 12px 50px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.evidenceImage{
    display: flex;
    justify-content: left;
}
.evidenceImage img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.roomName{
  border: 1px solid rgba(208, 213, 221, 1);
  padding: 10px 12px;
  margin-bottom: 5px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.roomName p{
    margin-bottom: 0px;
}