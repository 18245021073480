.signUpSection {
  min-height: 100vh;
  background: rgba(249, 250, 251, 1);
  background-size: 100% 50%;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signUpBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signUpBox .singBackground {
  width: 900px;
  max-height: 430px;
  object-fit: cover;
  border-radius: 8px;
  object-fit: cover;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.welcomeText {
  text-align: center;
  margin-top: 25px;
}

.welcomeText h6 {
  font-family: "MyCustomFont";
  font-size: 36px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(29, 41, 57, 1);
}

.welcomeText p {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(102, 112, 133, 0.7);
}

.welcomeText button {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 18px;
  color: rgb(255, 255, 255);
  background: rgba(99, 102, 241, 1);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border: none;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.welcomeText button svg {
  margin-left: 8px;
}

/* tab panel */
.tabPanel {
  background-color: #fff;
  display: flex;
  width: 880px;
  overflow: hidden;
  padding: 40px 32px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.tabPanel .tabHeading {
  float: left;
  width: 30%;
  border-right: 1px solid rgba(234, 236, 240, 1);
}

.tabPanel .tabHeading ul {
  list-style: none;
  padding-left: 0px;
}

.tabPanel .tabHeading ul li {
  display: flex;
  margin-top: 20px;
  margin-bottom: 22px;
  cursor: pointer;
}

.arrowLine {
  margin-right: 12px;
}

.arrowLine .checkCircle {
  background-color: #fff;
}

.arrowLine .checkCircle.done:after {
  height: 0;
  width: 0;
}

.arrowLine .checkCircle::after {
  width: 2px;
  height: 42px;
  background-color: rgba(234, 236, 240, 1);
  position: absolute;
  top: 8px;
  position: relative;
  display: block;
  content: "";
  left: 11px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.arrowLine .checked::after {
  background-color: rgba(99, 102, 241, 1);
}

.arrowHeading h6 {
  margin-top: 6px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 4px;
}

.tabPanel .arrowHeading h6.selected {
  color: #3d3fa1;
}

.tabPanel .arrowHeading p.selected {
  color: rgba(99, 102, 241, 1);
}

.arrowHeading p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.selected .arrowHeading p,
.selected .arrowHeading h6 {
  color: rgba(80, 82, 201, 1);
}

.selected .arrowLine .checkCircle img {
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.tabContent {
  float: left;
  padding-left: 40px;
  width: 70%;
}

/* profile info */
.profileInfo h1,
.passwordInfo h1,
.billingInfo h1,
.agreementInfo h1 {
  font-family: "MyCustomFont";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}
.agreementInfo p {
  font-family: "MyCustomFont";
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}

.memberSignUp input {
  width: 100%;
}

.tabPanelBtn {
  margin-top: 40px;
  display: flex;
  justify-content: end;
}

.tabPanelBtn .continue {
  margin-left: 24px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 18px;
  color: rgb(255, 255, 255);
  background: rgba(99, 102, 241, 1);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border: none;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.tabPanelBtn .continue svg,
.tabPanelBtn .skip svg {
  margin-left: 6px;
}

.tabPanelBtn .backBtn {
  width: 108px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 18px;
  color: rgba(186, 199, 206, 1);
  background: rgb(255, 255, 255);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border: 1px solid rgba(186, 199, 206, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.tabPanelBtn .backBtn svg,
.tabPanelBtn .back svg {
  margin-right: 6px;
}

.tabPanelBtn .back,
.tabPanelBtn .skip {
  margin-left: 24px;
  width: 108px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 18px;
  color: rgba(99, 102, 241, 1);
  background: rgb(255, 255, 255);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border: 1px solid rgba(99, 102, 241, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.memberImg {
  position: relative;
  margin-bottom: 15px;
}

.memberImg .member {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.round {
  position: absolute;
  bottom: -10px;
  left: 33px;
}

.agreementText {
  max-height: 230px;
  overflow-y: auto;
  padding: 12px 24px;
  margin-bottom: 25px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.agreementText h6 {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}

.agreementText p {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 0.7);
}

.agreeCheck .tableCheckBox .checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
}

.agreeCheck .tableCheckBox .checkmark {
  height: 16px;
  width: 16px;
}

.agreeCheck .agreement {
  margin-top: 0px;
}

.agreeCheck .agreement .agreeText {
  margin-left: 10px;
}

.agreeCheck,
.signBox,
.signShowBox {
  padding: 18px 24px;
  margin-bottom: 25px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.signBox {
  text-align: center;
  cursor: pointer;
}

.signBox p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(105, 65, 198, 1);
}

.finishText {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.finishText img {
  width: 174px;
  height: 180px;
}

.finishText h6 {
  margin-top: 25px;
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(29, 41, 57, 1);
}

.finishText p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(52, 64, 84, 0.7);
}

.signatureTab h1 {
  font-family: "MyCustomFont";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}

.signatureBox {
  margin-top: 20px;
}

.signatureTab .nav-item a {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 112, 133, 1);
}

.signatureTab .nav-item a.active {
  background: rgba(242, 244, 247, 1);
  color: rgba(52, 64, 84, 1);
}

.signaturePad p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}

.signaturePad .sigCanvas {
  width: 100%;
  height: 240px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.typeSignature {
  padding: 32px 16px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.typeSignature p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}

.typeSignature input {
  border: none;
  border-bottom: 1.5px solid rgba(208, 213, 221, 1);
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.signShowBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signShow .sigImage {
  width: 120px;
  height: 72px;
  background-color: rgba(248, 250, 251, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.signShow .signatureName {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-top: 10px;
  display: block;
  min-width: 72px;
  padding: 8px 16px;
  background-color: rgba(248, 250, 251, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.signChange button {
  background-color: #fff;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(105, 65, 198, 1);
  border: none;
  margin: 0px 5px;
}

.passMatch {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(248, 50, 76);
}
.disable {
  margin-left: 24px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 18px;
  color: rgb(255, 255, 255);
  background: rgba(186, 199, 206, 1);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border: none;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.StripeElement {
  align-items: center;
  background: #fff;
  border: 1px solid #d2d2d2;
  color: hsla(216, 2%, 46%, 0.8);
  display: grid;
  height: 50px;
  padding: 0 20px;
  position: relative;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.cardNumber {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.cardNumber label {
  font-family: "MyCustomFont";
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2937;
  margin-bottom: 7px;
}
.cardInput {
  width: 48%;
}

.bookTour .memberInput label,
.bookTour .numberInput label,
.passwordInfo .loginInput label,
.billingInfo .memberInput label,
.profileInfo .memberInput label {
  color: var(--Typography-Body, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.profileInfo .react-tel-input .form-control {
  height: 46px !important;
  border-radius: 12px !important;
}

.agreementInfo .agreement .tableCheckBox,
.billingInfo .agreement .tableCheckBox {
  width: 24px;
}

.agreementInfo .agreementText::-webkit-scrollbar {
  width: 4px;
}

.agreementInfo .agreementText::-webkit-scrollbar-track {
  background: inherit;
}

.agreementInfo .agreementText::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 10px;
  border: none;
}

.scroll-container {
  padding: 8px;
}

.agreementInfo .scroll-container {
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
}

@media only screen and (max-width: 1000px) {
  .signUpBox .singBackground {
    width: 80vw;
    height: auto;
  }

  .tabPanel {
    flex-direction: column;
    width: 90vw;
  }
  .tabPanel .tabHeading {
    border: none;
    width: 100%;
    float: none;
  }
  .signUp__tabPanel .tabHeading ul {
    display: flex;
    justify-content: center;
  }
  .tabPanel .tabHeading ul li {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: space-between;
  }
  .tabPanel .tabHeading ul li:not(:last-child) {
    width: 160px;
    margin-right: 20px;
  }
  .arrowHeading {
    min-height: 66px;
  }
  .arrowLine .checkCircle::after {
    width: 140px;
    height: 2px;
    top: -12px;
    left: 30px;
  }

  .profileInfo h1,
  .passwordInfo h1,
  .billingInfo h1,
  .agreementInfo h1 {
    text-align: center;
  }
  .tabContent {
    width: 100%;
    padding-left: 0;
  }
  .imageUpload {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .welcomeText h6 {
    font-size: 26px;
    padding: 0 20px;
  }

  .tabPanel .tabHeading ul li:not(:last-child) {
    width: 80px;
    margin-right: 20px;
  }
  .arrowHeading p {
    display: none;
  }
  .arrowLine .checkCircle::after {
    width: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .welcomeText h6 {
    font-size: 22px;
    padding: 0 10px;
  }

  .tabPanel .tabHeading ul li:not(:last-child) {
    width: 65px;
    margin-right: 20px;
  }
  .arrowLine .checkCircle::after {
    width: 45px;
  }
}

@media only screen and (max-width: 350px) {
  .tabPanel {
    width: 100vw;
    border-radius: 0;
    padding: 30px 15px;
  }
  .logo img {
    margin-bottom: 20px;
  }
}
