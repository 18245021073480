.mainContent {
  background: #f7f8fa;
  /* min-height: 100vh; */
  padding: 32px 32px 33px 32px;
  width: 100%;
  height: calc(100vh - 72px);
  overflow-y: auto;
}

.memberBox {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #eaecf0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  border: 1px solid #eaecf0;
}

.table > thead {
  background-color: #f9fafb;
}

.topLine {
  padding: 19px 24px;
  /* height: 85px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  flex-wrap: wrap;
  width: 100%;
}

.topLine h6 {
  font-family: "MyCustomFont";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin-bottom: 2px;
}

.topLine h6 svg {
  color: rgba(102, 112, 133, 1);
  margin-right: 12px;
}

.topLine button,
.topLine a,
.topLine a:hover {
  background: #6366f1;
  padding: 12px 16px;
  border: none;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  text-decoration: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.topLine button svg,
.topLine a svg,
.topLine a:hover svg {
  margin-right: 5px;
}

.topLine .breadLink,
.topLine .breadLink:hover {
  font-family: "MyCustomFont";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin-bottom: 2px;
  background-color: #fff;
  padding: 0px;
}

.topLine .breadLink svg,
.topLine .breadLink svg:hover {
  color: rgba(102, 112, 133, 1) !important;
  margin-right: 12px !important;
}

.memberSearch {
  display: flex;
  flex-wrap: wrap;
}

.memberSearch input {
  border: none;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 30px 10px 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  background: #f4f7f8;
}

.memberSearch input::placeholder {
  color: #98a2b3;
}

.memberSearch .form-control:focus {
  color: none !important;
  background: #f4f7f8;
  border-color: none !important;
  outline: none;
  box-shadow: none !important;
}

.searchInput {
  position: relative;
}

.searchInput input {
  min-width: 360px;
}

.searchInput svg {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

.memberList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 224px);
}

.memberList .table-responsive {
  width: 100%;
}

.memberList table tbody tr td {
  vertical-align: middle;
}

.memberList table tbody tr td:nth-child(3) {
  color: #101828;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.memberList table tbody tr td:nth-child(4),
.memberList table tbody tr td:nth-child(5) {
  color: #475467aa;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.memberList table tbody tr td img {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

/* .memberList table thead tr th .tableCheckBox,
.memberList table tbody tr td .tableCheckBox {
    width: 12px;
} */

.tableAction .view {
  border: 1px solid #6366f1;
  color: #6366f1;
  width: 40px;
  height: 40px;
  padding: 0px;
  vertical-align: middle;
}

.tableAction .view:hover {
  background-color: #6366f1;
  color: #ffffff;
}

.tableAction .edit {
  color: #667085;
  width: 40px;
  height: 40px;
  padding: 0px;
  text-align: center;
}

.tableAction .edit img {
  width: 20px;
  height: 20px;
}

.tableAction .edit:hover {
  color: #6366f1;
}

.tableAction .assign {
  width: 79px;
  height: 40px;
  border: 1px solid #6366f1;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #475467;
}

.tableAction .assign:hover {
  color: #ffffff;
  background-color: #6366f1;
}

.memberList table thead tr,
.memberList table thead tr th {
  background-color: #f4f7f8;
}

.memberList table thead tr th {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
  height: 56px;
  vertical-align: middle;
  white-space: nowrap;
}

.memberList table thead tr th:first-child,
.memberList table tbody tr td:first-child {
  width: 68px;
  vertical-align: middle;
  text-align: center;
}

.memberList table thead tr th:nth-child(2),
.memberList table tbody tr td:nth-child(2) {
  width: 94px;
  text-align: center;
}

.memberList table thead tr th:nth-child(4) {
  width: 284px;
  text-align: left;
}

.memberList table thead tr th:nth-child(5) {
  width: 206px;
  text-align: left;
}

.memberList table thead tr th:nth-child(6) {
  width: 206px;
  text-align: left;
}

.memberList table thead tr th:nth-child(7) {
  width: 116px;
  text-align: center;
}

.memberList table tbody tr td:nth-child(7) {
  width: 116px;
  text-align: center;
  display: flex;
  justify-content: center;
}

/* pagination */
.paginationBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 20px;
}

.tableNumber {
  display: flex;
  align-items: center;
}

.tableNumber .paginationDropdown {
  margin-right: 10px;
}

.paginationDropdown button {
  width: 80px;
  height: 40px;
  text-align: left;
  background-color: #fff;
  color: #98a2b3;
  font-weight: 500;
  border: 1px solid #d0d5dd;
  padding: 0px 12px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.paginationDropdown button:hover {
  background-color: #fff;
  color: #98a2b3;
  border: 1px solid #d0d5dd;
}

.paginationDropdown .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0px;
  vertical-align: none !important;
  content: "";
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}

.paginationDropdown .dropdown-toggle:before {
  position: absolute;
  right: 12px;
  top: 11px;
  display: inline-flex;
  line-height: 0;
  content: url("../../Assets/Images/icon/downIcon.svg");
  transform-origin: center;
  transition: transform 0.35s ease;
  -webkit-transition: transform 0.35s ease;
  -moz-transition: transform 0.35s ease;
  -ms-transition: transform 0.35s ease;
  -o-transition: transform 0.35s ease;
}

.paginationDropdown .dropdown-menu {
  min-width: 80px !important;
}

.paginationNumber button {
  width: 40px;
  height: 40px;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
}

.paginationNumber .disable {
  pointer-events: none;
  background-color: rgb(228, 228, 228);
}

.paginationNumber button:first-child,
.paginationNumber button:last-child {
  width: 120px;
}

.paginationNumber button:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.paginationNumber button:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.paginationNumber button:first-child svg {
  font-size: 14px;
  padding-right: 8px;
}

.paginationNumber button:last-child svg {
  font-size: 16px;
  padding-left: 8px;
}

.tableNumber p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  margin-bottom: 0px;
}

#view-member .react-tel-input .form-control,
.memberPhone .react-tel-input .form-control,
.memberPhone .react-tel-input .form-control:focus {
  height: auto !important;
  padding-left: 0px !important;
  border: none !important;
  background-color: transparent !important;
  font-family: "MyCustomFont" !important;
  box-shadow: none !important;
}

#view-member .react-tel-input input {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 0.7);
  margin-bottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
}

#view-member .react-tel-input .selected-flag,
.memberPhone .react-tel-input .selected-flag {
  display: none !important;
}

#view-member .react-tel-input .flag-dropdown,
.memberPhone .react-tel-input .flag-dropdown {
  border: none !important;
}

.memberName {
  display: flex;
  align-items: center;
}

.memberName img {
  margin-right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.memberName h6 {
  font-family: "MyCustomFont";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.memberName h5 svg {
  margin-right: 20px;
}

.memberName p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 0px;
}

.memberName .backDashboard,
.memberName .backDashboard:hover,
.tableHeading .backDashboard,
.tableHeading .backDashboard:hover {
  font-family: "MyCustomFont";
  font-size: 17px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 0px;
  background-color: #fff;
  padding: 0px 8px;
  display: flex;
}

.membersDetails {
  background-color: #fff;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.membersDetails .topLine .editBtn {
  display: flex;
  align-items: center;
}

.membersDetails .topLine .editBtn .edit {
  border: 1px solid rgba(99, 102, 241, 1) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: "MyCustomFont";
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px !important;
  text-align: left;
  color: rgba(99, 102, 241, 1) !important;
  background-color: #fff !important;
}

.membersDetails .topLine .editBtn .edit img {
  margin-right: 8px;
}

.membersDetails .topLine .editBtn .invite {
  margin-right: 10px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: rgba(52, 64, 84, 1);
  background: rgba(242, 244, 247, 1);
  padding: 4px 14px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.memberInfo {
  display: flex;
  flex-wrap: wrap;
}

.memberInfo .memberInfoBox {
  width: 33.3%;
  padding: 24px;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.memberInfo .memberInfoBox h6 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.memberInfo .memberInfoBox p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 0.7);
  margin-bottom: 0px;
}

.invoiceHistory {
  margin-top: 20px;
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 20px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.invoiceMemberList {
  max-height: 650px;
  background-color: white;
  overflow-y: auto;
}

.invoiceHistory h6,
.memberAssign h6,
.memberBooking h6,
.memberNotes h6 {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: rgba(29, 41, 57, 1);
}

.invoiceHistory .invoiceBox {
  margin-bottom: 15px;
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.invoiceHistory .invoiceHeading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  padding-bottom: 20px;
}

.invoiceHistory .invoiceBox .invoiceName {
  display: flex;
  align-items: center;
}

.invoiceHistory .invoiceBox .invoiceName h6 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
  margin-right: 15px;
  margin-bottom: 0px;
}

.invoiceHistory .invoiceBox .invoiceName p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
  margin-bottom: 0px;
}

.invoiceHistory .invoiceBox .invoiceName p img {
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.invoiceHistory .invoiceBox .invoiceName p span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.invoiceHistory .invoiceHeading .invoicePrice {
  display: flex;
  align-items: center;
}

.invoiceHistory .invoiceHeading .invoicePrice p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
  margin-bottom: 0px;
  margin-right: 20px;
  padding-right: 18px;
  border-right: 1px solid rgba(234, 236, 240, 1);
}

.invoiceHistory .invoiceHeading .invoicePrice p span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.invoiceBox .invoiceDetails {
  display: flex;
  justify-content: space-between;
}

.invoiceBox .invoiceDetails .assign img {
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.invoiceBox .invoiceDetails .assign h6 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
  margin-bottom: 15px;
}

.invoiceBox .invoiceDetails .assign p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.invoiceBox .invoiceDetails .assign .status span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: auto;
  padding: 5px 10px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.invoiceBox .invoiceDetails .assign .status .paid {
  color: rgba(2, 122, 72, 1);
  background: rgba(236, 253, 243, 1);
}

.invoiceBox .invoiceDetails .assign .status .unpaid {
  color: #c01048;
  background: #fff1f3;
}

.invoiceBox .invoiceDetails .assign .status .draft {
  color: rgba(52, 64, 84, 1);
  background: rgba(242, 244, 247, 1);
}

.memberInvoice {
  display: flex;
  padding: 24px;
}

.memberInvoice .invoiceLeft {
  width: 60%;
}

.memberInvoice .invoiceRight {
  width: 40%;
  padding: 0px 20px 20px 20px;
}

.memberAssign {
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.memberAssign .invoiceHeading {
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.memberAssign .invoiceHeading .invoiceName {
  display: flex;
}

.memberAssign .invoiceHeading .invoiceName img {
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.memberAssign .invoiceHeading .invoiceName p {
  margin-bottom: 0px;
}

.memberAssign .invoiceHeading .invoiceName p span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
  margin-right: 15px;
  margin-bottom: 0px;
}

.memberAssign .invoiceHeading .invoicePrice {
  display: flex;
}

.memberAssign .invoiceHeading .invoicePrice p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
  margin-bottom: 0px;
  margin-right: 20px;
  padding-right: 18px;
  border-right: 1px solid rgba(234, 236, 240, 1);
  display: flex;
  align-items: center;
}

.memberBooking .bookingList {
  margin-top: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.memberBooking .bookingList img {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.memberBooking .bookingList .spacesInfo {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  padding: 0px;
}

.memberBooking .bookingList .spacesInfo h6 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.memberBooking .bookingList .spacesInfo p {
  font-family: "MyCustomFont";
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 0px;
}

.invoiceRight .memberBooking {
  padding: 20px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.invoiceLeft .memberNotes {
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.invoiceHistory .balance {
  color: var(--Gray-600, #475467aa);
  font-size: 16px;
  line-height: 26px;
}

.memberBooking p,
.invoiceHistory p,
.memberAssign p,
.memberNotes p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(152, 162, 179, 0.7);
}

#member .react-tel-input .form-control {
  color: #475467aa;
}

#view-member .topLine {
  height: 114px;
}

.disableMember {
  border: 1px solid rgba(240, 68, 56, 1) !important;
  color: rgba(240, 68, 56, 1) !important;
  background-color: #fff !important;
  margin-right: 15px !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: "MyCustomFont";
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px !important;
  text-align: left;
}

.editBtn .agreement {
  margin-top: 0px !important;
  border: 1px solid #8e44ad !important;
  color: #8e44ad !important;
  background-color: #fff !important;
  margin-right: 15px !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: "MyCustomFont";
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px !important;
  text-align: left;
}

.activeMember {
  border: 1px solid rgba(2, 122, 72, 1) !important;
  color: rgba(2, 122, 72, 1) !important;
  background-color: #fff !important;
  margin-right: 15px !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: "MyCustomFont";
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px !important;
  text-align: left;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* CSS rules for Safari and iOS */
    @media (max-width: 800px) {
      .mainContent {
        padding-bottom: 115px !important;
      }
    }
  }
}

@media (max-width: 800px) {
  .searchInput input {
    min-width: 100%;
  }

  .mainContent {
    min-height: -webkit-fill-available;
    height: 100%;
    padding: 100px 20px 57px 20px;
    overflow-y: scroll;
    position: relative;
  }

  .paginationNumber button:first-child svg {
    font-size: 16px;
    padding-right: 0px;
  }

  .paginationNumber button:last-child svg {
    font-size: 16px;
    padding-left: 0px;
  }

  .paginationNumber button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .paginationNumber button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .paginationDropdown button {
    border-radius: 8px;
  }

  .memberBox {
    border: none;
  }
}

.incomplete-card {
  background-color: #fffcf5;
  border: 1px #fec84b solid;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  display: flex;
  align-items: flex-start;
  padding: 16px 20px;
  margin-bottom: 24px;
}

.incomplete-card .icon-cnt {
  width: 40px;
}

.incomplete-card .icon-cnt img {
  width: 20px;
}

.incomplete-card .content-cnt {
  width: 100%;
}

.incomplete-card .content-cnt p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 5px;
  color: #b54708;
}
.incomplete-card .content-cnt .onBoarding {
  margin-top: 7px;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: #027a48 !important;
}
.incomplete-card .content-cnt .reminder {
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.status.active {
  background-color: #ecfdf3 !important;
  color: #027a48 !important;
}

.status.inactive {
  background-color: #fae3d5 !important;
  color: #b54708 !important;
}

.memberAssignName p {
  margin-bottom: 0px;
}

.memberAssignName p img {
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.memberAssignName p span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
  margin-right: 15px;
  margin-bottom: 0px;
}

.mobileMember {
  display: none;
}
.mobileMember {
  display: none;
}

.tab {
  padding-left: 0;
  display: flex;
}

.tabItem {
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 600;
  color: #777;
}

.tabItemActive {
  position: relative;
  color: #6366f1;
}

.tabItemActive:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  background: #6366f1;
  height: 5px;
  border-radius: 20px;
}

#agreement-content {
  width: 100%;
  overflow-wrap: break-word;
}
.page-break {
  page-break-before: always;
  break-before: always;
}

@media screen and (max-width: 1400px) {
  .mainContent {
    min-height: -webkit-fill-available;
    overflow-y: scroll;
  }

  /* .memberBox {
        order: 2;
    } */
  .tableHeading {
    margin-bottom: 10px;
  }

  .mainTaskContent {
    overflow-y: scroll !important;
  }
}

@media screen and (max-width: 992px) {
  .mainContent {
    padding: 20px;
  }

  .myFilesTable {
    border: 1px solid #eaecf0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .topLine {
    padding: 20px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  .filterBtn {
    width: 105px;
  }

  .memberSearch {
    gap: 20px;
  }

  .uploadFileButton {
    width: 135px;
    height: 44px;
  }

  .filterBtn {
    width: 105px;
    height: 44px;
  }

  .filesList table thead tr th,
  .filesList table tbody tr td {
    padding: 18px 20px;
  }

  .paginationBox {
    padding: 20px;
    background-color: #fff;
  }

  .tableNumber .paginationDropdown {
    margin-right: 16px;
  }

  .paginationDropdown button {
    width: 90px;
  }
}

@media only screen and (max-width: 800px) {
  .pcMember {
    display: none;
  }
  .mobileMember {
    display: block;
  }
  .mobileMember {
    display: block;
  }

  /* CSS rules for Safari */
  .mainContent {
    padding-bottom: 115px !important;
  }

  .mobileMember .bookingPerson .line-chart-up-04-icon {
    width: 14px;
    height: 24px;
    margin-right: 0px;
    object-fit: cover;
  }

  .mobileMember .bookingPerson .postDelete svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .mobileMember .bookingPerson .tableLink a {
    color: rgba(16, 24, 40, 1);
    font-size: 14px;
    font-weight: 800;
  }

  .mobileMember .bookingPerson .tableLink p {
    color: #475467aa;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
  }

  .mainContent {
    margin-top: 70px;
    background-color: #f7f8fa;
    padding-bottom: 115px;
  }

  .myFilesTable {
    border: unset;
  }

  .tableNumber {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    /* margin-bottom: 15px; */
  }

  .paginationNumber {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .searchInput input {
    min-width: 100%;
  }
  .paginationBox {
    /* border: 1px solid rgba(234, 236, 240, 1); */
    flex-direction: column;
    margin-bottom: 0px;
    padding: 20px 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .paginationButtonText {
    display: none;
  }

  .paginationNumber button:first-child,
  .paginationNumber button:last-child {
    width: 40px;
  }

  .topLine button svg {
    margin-right: 0px;
  }

  .memberList {
    display: none;
  }

  .mobileMemberInfo .tableHeading,
  .mobileMemberInfo .mobileEditBtn,
  .mobileMemberInfo .mobileDetails {
    background-color: #fff;
    padding: 20px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .mobileMemberInfo .mobileDetails {
    margin-top: 20px;
  }

  .mobileMemberInfo,
  .mobileMemberInfo .memberName {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mobileMemberInfo .memberName img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .mobileEditBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  .mobileEditBtn button {
    min-height: 40px;
    padding: 14px 18px;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    background-color: #fff;
    font-family: "MyCustomFont";
    margin-right: 0px !important;
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  .mobileEditBtn .edit {
    border: 1px solid rgba(99, 102, 241, 1);
    color: rgba(99, 102, 241, 1);
  }

  .mobileMemberInfo .memberName .invite {
    position: absolute;
    top: 0px;
    right: 20px;
    padding: 2px 16px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }

  .mobileDetails .memberInfoBox {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(234, 236, 240, 1);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgb(255, 255, 255);
    padding: 15px 20px;
  }

  .mobileDetails .memberInfoBox h6 {
    color: rgba(71, 84, 103, 1);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0em;
    font-family: "MyCustomFont";
    margin-bottom: 0px;
    text-wrap: nowrap;
  }

  .mobileDetails .memberInfoBox p {
    color: rgba(71, 84, 103, 1);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0em;
    font-family: "MyCustomFont";
    margin-bottom: 0px;
  }

  .mobileDetails .memberInfoBox .react-tel-input input {
    text-align: end !important;
    padding: 0px !important;
    color: rgba(71, 84, 103, 1) !important;
  }

  .mobileMemberTab {
    margin-top: 20px;
    background-color: #fff;
  }
  .mobileMemberTab .nav-tabs .nav-item .nav-link {
    font-family: "MyCustomFont";
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: rgba(29, 41, 57, 1);
  }
  .mobileMemberTab .nav-tabs .nav-item .nav-link.active {
    border: none;
    border-bottom: 2px solid rgba(99, 102, 241, 1) !important;
    font-family: "MyCustomFont";
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: rgba(29, 41, 57, 1);
  }
  .mobileMemberTab .tabContent {
    border: 1px solid rgba(234, 236, 240, 1);
    padding: 15px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
  .AssignTabContent {
    background-color: #fff;
  }
  .mobileAssignment {
    border: 1px solid rgba(234, 236, 240, 1);
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
  .mobileAssignment .assignName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 0px;
    margin-top: 0px;
  }
  .mobileAssignment .assignName p {
    font-family: "MyCustomFont";
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 24, 40, 1);
    margin-right: 15px;
    margin-bottom: 0px;
  }
  .mobileAssignment .assignName button {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(99, 102, 241, 1);
  }
  .mobileAssignment .assignName p img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .spaceImg {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .mobileAssignment .invoicePrice {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .mobileAssignment .invoicePrice p {
    font-family: "MyCustomFont";
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    margin-left: 15px;
    margin-bottom: 0px;
  }
  .mobileAssignment .invoicePrice p span {
    font-weight: 400;
  }
  .mobileMemberTab .invoiceMemberList .mobileInvoiceHeading .invoiceRate {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 800;
    color: rgba(71, 84, 103, 1);
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  .mobileMemberTab
    .invoiceMemberList
    .mobileInvoiceHeading
    .invoiceRate
    button {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(99, 102, 241, 1);
    margin-left: 10px;
  }
}
