
.lightModal .modal-dialog{
    justify-content: center;
}
.lightModal .modal-content{
    height: 420px !important;
    width: 620px !important;
}
.lightBox {
    padding: 20px;
    width: 100%;
    height: 100%;
}
.lightBox img{
    width: 100%;
    height: 100%;
}

.lightBox .closeModal {
    height: 40px;
    width: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    color: #101828;
    background: #E2E3FF;
    box-shadow: 0px 3px 8px 1px #64646491;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}


.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    overflow: hidden;

  }
  
  .lightbox-inner {
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}
 
  .lightbox-image {
     max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
    margin: auto;
    text-align: center;
    inset: 0;
    background-color: #fff;
    box-shadow: 0px 3px 8px 1px #64646491;
    padding: 20px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }
  
  .lightbox-close {
    z-index: 999;
    height: 40px;
    width: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    color: #101828;
    background: #E2E3FF;
    box-shadow: 0px 3px 8px 1px #64646491;
    border: none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }