.visitorSection {
    min-height: 100vh;
    display: flex;
}

.visitorLeft {
    min-height: 100vh;

    width: 60%;
}

.visitorImage {
    background: rgba(249, 250, 251, 1);
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100% !important;
    padding: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 100vh;
}

.visitorLeft h5 {
    font-family: "MyCustomFont";
    font-size: 48px;
    font-weight: 700;
    line-height: 62px;
    text-align: left;
    color: white;
}

.visitorRight {
    padding: 40px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orLine {
    display: block;
    width: 300px;
    margin: 40px 0px;
}

.orLine h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #475467AA;
    line-height: 0.1em;
    margin: 10px 0 20px;
    font-size: 16px;
}

.orLine h2 span {
    background: #fff;
    color: #475467AA;
    padding: 0 10px;
    font-family: "MyCustomFont";
}

.visitorRight h5 {
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: rgba(29, 41, 57, 0.7);
}

.visitorRight .tapNext {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: white;
    margin-bottom: 0px;
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    background: rgba(99, 102, 241, 1);
    padding: 12px 16px;
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.reasonVisit .dropdown-toggle::after {
    display: none !important;
}

.reasonVisit .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visitorRight .success h5 {
    font-family: "MyCustomFont";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: rgba(29, 41, 57, 1);
}

.visitorRight .success h4 {
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    color: rgba(29, 41, 57, 0.7);
}

.visitorList table tbody tr td:nth-child(6) {
    display: revert !important;
}

.visitorList .reason {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.visitorList .reason a {
    font-weight: 600;
    text-decoration: none;
    color: #6366F1;
}

.removeVisitor img {
    width: 20px !important;
    height: 20px !important;
}

.visitorDownload {
    padding: 0px !important;
}

.memberList.visitorList table thead tr th:nth-child(1),
.memberList.visitorList table tbody tr td:nth-child(1),
.memberList.visitorList table thead tr th:nth-child(6),
.memberList.visitorList table tbody tr td:nth-child(6) {
    width: 6.25%;
}

.memberList.visitorList table thead tr th:nth-child(2),
.memberList.visitorList table tbody tr td:nth-child(2),
.memberList.visitorList table thead tr th:nth-child(4),
.memberList.visitorList table tbody tr td:nth-child(4) {
    width: 18.75%;
    text-align: start;
}

.memberList.visitorList table thead tr th:nth-child(5),
.memberList.visitorList table tbody tr td:nth-child(5) {
    width: 12.5%;
}

.memberList.visitorList table thead tr th:nth-child(3),
.memberList.visitorList table tbody tr td:nth-child(3) {
    width: 37.5%;
}

.memberList.visitorList table thead tr th {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: var(--Gray-600, #475467);
}

.memberList.visitorList table tbody tr td {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: #475467;
}

.memberList.visitorList table tbody tr td:nth-child(2),
.memberList.visitorList table tbody tr td:nth-child(5) {
    font-weight: 700;
    line-height: 30px;
    color: #101828 !important;
}

#visitor .memberList.visitorList table tbody tr td {
    color: #475467AA;
}

.visitorSection .memberInput input,
.visitorSection .memberInput .dropdown {
    width: 320px;
}

.visitorSection .memberInput .dropdown button {
    background-color: white;
}

.noneSelect {
    color: #98A2B3AA;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.visitorSection .memberInput .dropdown button .noneSelect img {
    margin-left: auto;
}

.scrollable-menu {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.visitor-dropdown-edge {
    margin-top: -2px !important;
    border-radius: 0px 0px 12px 12px !important;
    width: 100% !important;
}

.visitor-dropdown {
    border-radius: 12px 12px 0px 0px !important;
}

.visitorRight .callBack {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 0px;
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    background: rgba(99, 102, 241, 1);
    padding: 12px 16px;
    border: none;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.mobileVisitorHeading{
    display: none;
}

@media (max-width: 800px) {

    .visitorLeft,
    .visitorQr {
        display: none;
    }

    .visitorRight {
        width: 100%;
    }

    .visitorSection {
        align-items: center;
        padding: 20px;
        flex-direction: column;
    }

    .visitorRight {
        margin-top: 15px;
        background-color: #fff;
        width: 100%;
        padding: 20px;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
    }

    .visitorSection .memberInput input,
    .visitorSection .memberInput .dropdown,
    .visitorRight .memberInput {
        width: 100%;
    }

    .visitorRight .callBack {
        width: 100%;
    }

    .mobileVisitorHeading h5 {
        font-family: "MyCustomFont";
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        color: white;
    }
    .mobileVisitorHeading{
        display: block;
    }
}