.profileImageUpload {
    border: 1px solid rgba(208, 213, 221, 1);
    margin-right: 32px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.profileImageUpload .profileTitle {
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    padding: 12px 24px;
}

.profileImageUpload .profileTitle p {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(71, 84, 103, 1);
    text-wrap: nowrap;
}

.profileImageUpload .profileImgView {
    padding: 24px;
    text-align: center;
    border-bottom: 1px solid rgba(208, 213, 221, 1);
}
.profileImageUpload .profileImgView img{
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.profileImageUpload .profileFooter {
    padding: 12px 24px;
    display: flex;
    justify-content: center;
}

.profileImageUpload .profileFooter img {
    margin: 0px 8px;
    cursor: pointer;
}

.profileFooter .drop-file-input {
    padding: 0px;
    position: relative;
    height: auto;
    width: auto;
    border: none;
}

.profileFooter .drop-file-input .drop-file-input__label {
    padding: 0px;
}

.mobileAccountBtn .cancelBtn,.mobileAccountBtn .saveBtn{
    display: flex;
    font-family: "MyCustomFont";
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    padding: 12px 16px;
    align-items: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.mobileAccountBtn .cancelBtn{
    background: rgb(255, 255, 255);
    color: rgba(99, 102, 241, 1);
    border: 1px solid rgba(99, 102, 241, 1);
}
.mobileAccountBtn .saveBtn{
    margin-top: 20px;
    background: rgba(99, 102, 241, 1);
    border: 1px solid rgba(99, 102, 241, 1);
    color: #FFF;
}
.mobileAccountBtn{
    display: none;
}

.filledCard {
    max-width: 600px;
    padding: 20px;
}

.filledCard__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.filledCard .action {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    color: blue;
    margin-bottom: 0;
}

.filledCard__info {
    display: flex;
}

.filledCard__infoLabel {
    color: #aaa;
    margin-right: 40px;
}

.filledCard__infoValue {
    font-weight: 600;
}

.cardName::first-letter {
    text-transform: capitalize;
}

@media screen and (max-width: 800px) {
    .mobileAccountBtn{
        display: block;
    }
}