.eventList {
  width: 30%;
  background-color: rgb(255, 255, 255);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  position: relative;
}
.calenderList {
  height: calc(100% - 211px);
}
.eventSide {
  height: calc(100% - 38px);
  overflow-y: auto;
}
.addEvent {
  width: 100%;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  padding: 23px;
}

#calendar .calendar {
  box-shadow: 0px 23px 44px 0px rgba(176, 183, 195, 0.14);
  border-radius: 12px;
  position: relative;
  display: flex;
  background-color: #fff;
  height: calc(100vh - 247px);
  border: 1px solid #eaecf0;
}

#calendar .fullCalenderBox {
  border-left: 1px solid rgba(234, 236, 240, 1);
  width: 80%;
}

.addEvent button {
  background: rgba(99, 102, 241, 1);
  color: white;
  width: 100%;
  border: none;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.addEvent button svg {
  margin-right: 7px;
}

.eventUpcoming {
  padding: 24px;
  border-top: 1px solid rgba(234, 236, 240, 1);
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.eventHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.eventHeading p {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.eventHeading button {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(99, 102, 241, 1);
  background-color: transparent;
  border: none;
}

.eventDetails {
  margin-top: 15px;
  background: rgba(244, 247, 248, 1);
  border: 1px solid #eaecf0;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.eventDetails img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.eventInfo p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 2px;
}

.eventCategory {
  align-items: center;
  display: flex;
}

.eventCategory a {
  padding: 2px 8px;
  border: none;
  font-family: "MyCustomFont";
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.eventCategory a.task {
  color: rgba(61, 63, 161, 1);
  background: rgba(239, 240, 254, 1);
}

.eventCategory a.tour {
  color: rgba(23, 92, 211, 1);
  background: rgba(239, 248, 255, 1);
}

.eventCategory a.resource {
  color: rgba(2, 122, 72, 1);
  background: rgba(236, 253, 243, 1);
}

.eventCategory span {
  font-family: "MyCustomFont";
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(52, 64, 84, 1);
  display: flex;
  align-items: center;
}

.eventCategory svg {
  background: rgba(247, 144, 9, 1);
  color: rgba(247, 144, 9, 1);
  font-size: 10px;
  margin-right: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.eventFilters {
  margin-top: 24px;
  margin-bottom: 10px;
}
.eventFilters h1 {
  font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 41, 57, 1);
}

#calendar .filterCheck {
  padding: 20px 0;
  border-top: 1px solid rgba(234, 236, 240, 1);
}

.filterCheck ul {
  padding-left: 0px;
}

.filterCheck ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.filterCheck ul li div {
  display: flex;
  align-items: center;
}

.filterCheck ul li div p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 0px;
}

.filterCheck ul li .filterColor {
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 6px;
  margin-right: 10px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.filterCheck ul li .filterBlack {
  background: rgba(2, 122, 72, 1);
}

.filterCheck ul li .filterBlue {
  background: rgba(61, 63, 161, 1);
}

.filterCheck ul li .filterGreen {
  background: rgba(23, 92, 211, 1);
}

.filterCheck ul li .filterRed {
  background: rgba(240, 68, 56, 1);
}

.filterCheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #a8aeb9;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.filterCheckBox .checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #6366f1;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.fc-toolbar-chunk div {
  display: flex;
  align-items: center;
}

.fc-toolbar-chunk div .fc-prev-button,
.fc-toolbar-chunk div .fc-next-button {
  background-color: transparent;
  color: rgba(102, 112, 133, 1);
  text-transform: capitalize;
  border: none;
}

.fc-toolbar-chunk div h2 {
  font-family: "MyCustomFont";
  font-size: 16px !important;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(29, 41, 57, 1);
}

.fc-toolbar-chunk .fc-today-button,
.fc-button-group .fc-dayGridDay-button,
.fc-button-group .fc-dayGridWeek-button,
.fc-button-group .fc-dayGridMonth-button {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1) !important;
  border: 1px solid rgba(208, 213, 221, 1);
  text-transform: capitalize;
  background-color: transparent !important;
  padding: 12px 16px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.fc-scrollgrid-sync-inner a {
  text-decoration: none;
  font-family: "MyCustomFont";
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(102, 112, 133, 1);
  text-transform: uppercase;
}

.fc-header-toolbar {
  padding: 24px;
}

.fc .fc-daygrid-day-top {
  margin-top: 15px;
  justify-content: center;
  text-align: center;
  width: 60px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 7px !important;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 7px !important;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.paymentDetails .monthView .fc .fc-toolbar.fc-header-toolbar {
  border: none;
}

.fullCalenderBox .fc .fc-toolbar.fc-header-toolbar,
.monthView .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
}

.fullCalenderBox .fc-col-header thead {
  height: 60px;
}

.fc-theme-standard th {
  vertical-align: middle;
}

.fc-direction-ltr {
  height: 100%;
}

.monthView {
  height: 350px;
}

.monthView .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 2em;
  position: relative;
  display: none;
}

.monthView .fc .fc-scroller {
  overflow-y: hidden !important;
}

.monthView .fc .fc-toolbar-title {
  margin-left: 40px;
  margin-right: 40px;
}

.monthView .fc .fc-view-harness-active {
  min-height: 270px;
}

.monthView .fc .fc-scroller-liquid-absolute {
  inset: 0px;
  position: absolute;
  overflow: hidden !important;
}

.monthView .fc-theme-standard td,
.monthView .fc-theme-standard th {
  border: none;
}

.monthView .fc-theme-standard .fc-scrollgrid {
  border: none;
}

.monthView .fc .fc-daygrid-day.fc-day-today a {
  background: rgba(99, 102, 241, 1) !important;
  color: white !important;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fc .fc-daygrid-day.selected-date a {
  background-color: rgba(99, 102, 241, 1) !important;
  color: white !important;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.fullCalenderBox .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background: rgba(99, 102, 241, 1) !important;
  color: white !important;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.task-background {
  background-color: rgba(61, 63, 161, 1) !important;
  border: 1px solid rgba(61, 63, 161, 1) !important;
}
.tour-background {
  background-color: rgba(23, 92, 211, 1) !important;
  border: 1px solid rgba(23, 92, 211, 1) !important;
}

.resource-background {
  background-color: rgba(2, 122, 72, 1) !important;
  border: 1px solid rgba(2, 122, 72, 1) !important;
}
.event-red {
  background-color: rgba(2, 122, 72, 1) !important;
  border: 1px solid rgba(2, 122, 72, 1) !important;
}

@media screen and (max-width: 1400px) {
  #calendar .calendar {
    display: block;
    border: none;
    height: auto;
  }

  .eventList {
    display: flex;
    width: auto;
  }

  .eventList .events {
    width: 50%;
  }

  .eventUpcoming {
    border: none;
    width: 50%;
  }

  #calendar .fullCalenderBox {
    margin-top: 24px;
    padding: 0 24px 24px 24px;
    width: 100%;
  }

  .fc-header-toolbar {
    padding: 24px 0;
  }
  .fc-scrollgrid-sync-inner a {
    line-height: 14px;
  }
}

@media screen and (max-width: 800px) {
  .fc-header-toolbar {
    padding: 0px 0 14px;
  }

  .addMemberHeading p {
    font-size: 16px;
  }

  .addMemberHeading img {
    width: 35px;
  }

  .fc .fc-daygrid-day.fc-day-today a {
    background: rgba(99, 102, 241, 1) !important;
    color: white !important;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  .fc .fc-daygrid-day.selected-date a {
    background-color: rgba(99, 102, 241, 1) !important;
    color: white !important;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }

  .fc .fc-daygrid-day-top {
    margin-top: 15px;
    width: 40px;
  }
}
