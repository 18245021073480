.forgot-password {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: transparent;
}

.forgetBox {
    background-color: #fff;
    padding: 40px 32px;
    min-width: 536px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.forgetBox h6 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: rgba(16, 24, 40, 1);
    margin-bottom: 40px;
}

.forgetBtn {
    margin-top: 25px;
}

.forgetBox p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: rgba(52, 64, 84, 1)
}

.forgetBtn button,
.forgetBtn a {
    background: #6366F1;
    text-decoration: none;
    padding: 18px 36px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-decoration: none;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.forgetMember {
    border-bottom: 1px solid rgba(234, 236, 240, 1);
    margin-bottom: 15px;
}

.forgetMember label {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.forgetMember p {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: rgba(29, 41, 57, 1)
}

.forgetBox .passMatch {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: rgb(218, 17, 77);
}

@media (max-width: 800px) {
    .forgetBox {
        width: 100%;
        min-width: 100% !important;
    }

    .forgetBox {
        padding: 20px;
    }

    .forgetBtn button{
        width: 100%;
        justify-content: center;
    }
}