.dashboardStatus {
  background: rgba(255, 255, 255, 1);
  border: 1px solid #EAECF0;
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.dashboardStatus .card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}


.upcoming-bookings {
  margin-bottom: 20px;
}

.card-item {
  background-color: #F8FAFB;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.card-item a {
  text-decoration: none;
}

.card-title {
  font-family: "MyCustomFont";
  font-weight: 800;
  font-size: 14px;
  line-hight: 24px;
  color: #1D2939;
  width: 80%;
}

.plus-wrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unread-messages-title {}

.dashboardStatus .statusHeading {
  display: flex;
  align-items: end;
}

.dashboardStatus .statusHeading img {
  margin-right: 10px;
}

.dashboardStatus .statusHeading p {
  font-family: "MyCustomFont";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.statusContent p {
  margin-top: 15px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(52, 64, 84, 1);
  margin-bottom: 0px;
}

/* CalendarStyles.css */

.custom-day-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-day-cell .day-name {
  color: var(--Gray-500, #667085);
  font-family: "MyCustomFont";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 183.333% */
}

.custom-day-cell .day-number {
  color: var(--Gray-600, #475467);
  margin-top: 12px;
  margin-bottom: 8px;
  z-index: 999;
  text-align: center;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  /* 171.429% */
}

.weeklyCalender .fc-header-toolbar {
  padding: 0px !important;
  margin-left: 10px !important;
  margin-bottom: 20px !important;
}

.weeklyCalender .fc-theme-standard td,
.weeklyCalender .fc-theme-standard th,
.weeklyCalender .fc-scrollgrid {
  border: none !important;
}

.weeklyCalender .fc {
  border-radius: 12px;
  border: 1px solid var(--Brand-Divider, #EAECF0);
  background: #FFF;
  margin-top: 24px;
  padding: 24px 16px;
}

.weeklyCalender .fc-scroller {
  overflow-y: scroll;
}

/* .fc-daygrid-day-events {
    display: none !important;
} */

.weeklyCalender .fc-toolbar-title {
  color: var(--Typography-SubHeading, #1D2939);
  font-family: "MyCustomFont";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  /* 162.5% */
}

.upcoming-booking-card {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Secondary-25, #F8FAFB);
  margin-top: 16px;
}

.upcoming-booking-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.upcoming-booking-card-title {
  color: var(--Typography-SubHeading, #1D2939);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  margin: 0px;
  margin-bottom: 4px;
  line-height: 24px;
  /* 183.333% */
}

.upcoming-booking-card-date {
  color: var(--Typography-Body, #344054);
  text-align: center;
  font-family: "MyCustomFont";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 183.333% */
  margin: 0px;

}

.invoice-divider {
  width: 100%;
  height: 1px;
  margin-top: 24px;
  background: var(--Brand-Divider, #EAECF0);
}

.invoice-card {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  margin-top: 24px;
  border: 1px solid var(--Brand-Divider, #EAECF0);
}

.invoice-header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.invoice-title {
  color: var(--Gray-900, #101828);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  /* 171.429% */
  margin: 0;
}

.invoice-price {
  color: var(--Gray-600, #475467);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  /* 171.429% */
  margin: 0;
}

.invoice-price span {
  color: var(--Gray-600, #475467);
  font-family: "MyCustomFont";
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;
}

.invoice-subHeading {
  color: var(--Gray-600, #475467);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.invoice-date {
  color: var(--Gray-600, #475467);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  margin-top: 16px;
  text-decoration: none;
}

.vertical-divider {
  width: 1px;
  height: 36px;
  background: var(--Brand-Divider, #EAECF0);
}

.invoice-name {
  color: var(--Gray-900, #101828);
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  /* 171.429% */
  margin: 0;
}

.invoice-button {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Brand-Brand-Primary, #6366F1);
}

.invoice-button:hover {
  background-color: transparent !important;
}

.invoice-name-flex {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.invoice-status {
  border-radius: 16px;
  background: var(--Error-50, #FEF3F2);
  mix-blend-mode: multiply;
  display: flex;
  padding: 2px 10px;
  align-items: center;
  width: fit-content;
  margin-top: 16px;
}

.invoice-status p {
  color: var(--Error-700, var(--Error-700, #B42318));
  text-align: center;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  /* 142.857% */
  margin: 0px;
}
.custom-day-cell.active-day .day-number{
  background: rgba(99, 102, 241, 1) !important;
    color: white !important;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media (max-width: 800px) {
  .dashboardStatus .card-grid{
    display: flex;
    flex-direction: column;
  }
}