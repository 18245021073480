.websiteBox {
  margin-top: 24px;
  border: 1px solid #eaecf0;
  padding: 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.websiteHeading {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.websiteHeading h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #344054;
}
.colorPreview {
  width: 70px;
  height: 45px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.websiteBox .memberInfos {
  border: 1px solid rgba(234, 236, 240, 1);
  padding: 10px 12px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.websiteBox .logoImageBox,
.websiteBox .drop-file-input {
  height: auto !important;
}
.websiteBox .logoImageBox {
  width: auto;
  padding: 0px;
}

.websiteBox .widthLogoBox .change-logo {
  object-fit: cover;
  width: 100%;
}
.websiteBox .largeImage {
  max-height: 230px;
  width: 238px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.websiteBox .trashBtn {
  margin: 0px 20px;
  background-color: #f2f4f7;
  padding: 7px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.websiteBox .logoImageBox p {
  margin-left: 20px;
}
.websiteBox .widthLogoBox {
  max-height: 140px;
  min-width: 238px !important;
  padding-left: 20px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.websiteBox .companyName {
  border-bottom: none;
}
.websiteBox .colorInput,
.websiteBox .colorInput:hover {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.websiteBox .colorBox,
.websiteBox .colorBox:focus,
.websiteBox .colorBox:focus-visible,
.websiteBox .colorBox:hover {
  height: auto;
  padding: 0px;
  border: none;
  width: 100px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
