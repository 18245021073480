.userInfo {
    display: flex;
    align-items: center;
}

.userInfo img {
    border: 2px solid #FFAB00;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.userInfo p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #1D2939;
    line-height: 12px;
}

.mailOptions p {
    margin-bottom: 0px;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #1D2939;
    line-height: 12px;
}

.uploadBtn {
    display: flex;
    justify-content: end;
}

.uploadBtn button {
    box-shadow: 0px 6px 30px 0px rgba(218, 228, 233, 0.16);
    background: rgba(186, 199, 206, 1);
    padding: 14px 35px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    color: white;
    text-align: center;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberScanBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.memberScanBtn .scan {
    background: rgba(99, 102, 241, 1);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    color: white;
    padding: 14px 20px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.memberScanBtn .delete {
    background: rgba(240, 68, 56, 1);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    color: white;
    padding: 14px 20px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.mailStatus .pending {
    font-family: 'MyCustomFont';
    background-color: rgba(255, 250, 235, 1);
    color: rgba(181, 71, 8, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 3px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.mailStatus .shredding,
.mailStatus .scanning {
    font-family: 'MyCustomFont';
    background-color: rgba(254, 243, 242, 1);
    color: rgba(180, 35, 24, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 3px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.mailStatus .shredded,
.mailStatus .scanned {
    font-family: 'MyCustomFont';
    background-color: rgba(242, 244, 247, 1);
    color: rgba(52, 64, 84, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    padding: 3px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.mailCloseBtn .close {
    background: rgb(255, 255, 255);
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border: 1px solid rgba(99, 102, 241, 1);
    color: rgba(99, 102, 241, 1);
    padding: 14px 35px;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.deleteText {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(152, 162, 179, 1);
    margin-left: 15px;
}

.mailSearch {
    display: none;
}

@media (max-width: 800px) {
    .mailSearch {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
    }

    .mailSearch button {
        margin-left: 15px;
        width: 44px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(99, 102, 241, 1);
        box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
        color: white;
        border: none;
        padding: 12px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
}