.chat {
    box-shadow: 0 23px 44px rgba(176, 183, 195, 0.14);
    flex-direction: row;
    align-items: flex-start;
    color: var(--gray-400);
    height: calc(100vh - 140px);
    border: 1px solid rgba(234, 236, 240, 1);
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.messageChat {
    height: calc(100vh - 140px);
    background-color: rgb(255, 255, 255);
    overflow-y: auto;
}

.contacts {
    border-radius: var(--br-mini) 0 0 var(--br-mini);
    background-color: white;
    border-right: 2px solid #EAECF0;
    gap: var(--gap-5xl);
    height: 100%;
    width: 360px;
}

.contact,
.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.all-messages-parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EAECF0;
    min-height: 96px;
}

.contact .avatar-parent img {
    margin-right: 15px;
}

.chatList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    cursor: pointer;
    border-radius: 12px;
}

.chatList .chatBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatList .chatBox .avatar {
    margin-right: 16px;
}

.info-circle-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.contacts-child {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--brand-divider);
    box-sizing: border-box;
    height: 1px;
}

.contact {
    padding: 0 var(--padding-5xl);
    gap: var(--gap-base);
    color: var(--typography-body);
    width: 100%;
}

.contact1 {
    width: 317px;
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-6xs);
}

.contact1:hover {
    cursor: pointer;
}

.chatter-select {
    background-color: rgba(244, 247, 248, 1);
}

.avatar-parent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.avatar {
    width: 48px;
    position: relative;
    border-radius: var(--br-181xl);
    height: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.avatar-online-indicator {
    position: absolute;
    right: -0.5px;
    bottom: 5px;
    border-radius: var(--br-7xs);
    background-color: var(--success-500);
    border: 1.5px solid var(--base-white);
    box-sizing: border-box;
    width: 15px;
    height: 15px;
}

.text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.bogdan-krivenchenko {
    width: 149px;
    position: relative;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: var(--Typography-Body, #344054);
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
}

.hi-how-are {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-xs);
    line-height: 22px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--Typography-Muted, #98A2B3);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
}

.parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    text-align: right;
    font-size: 10px;
    color: var(--gray-400);
}

/* complete css */


/* .logo-child,
.vector-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 24.59px;
    height: 24.86px;
} */

.logo-child {
    top: 18.5px;
    left: 2.58px;
    background-color: var(--color-khaki);
    width: 3.87px;
    height: 3.87px;
    transform: rotate(-0.49deg);
    transform-origin: 0 0;
}


.buzz-coworking,
.main-street {
    align-self: stretch;
    position: relative;
    line-height: 24px;
}

.buzz-coworking {
    font-weight: 600;
}

.main-street {
    color: var(--gray-400);
}

.chevron-selector-vertical-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.label,
.text-parent {
    display: flex;
    justify-content: flex-start;
}

.text-parent {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.label {
    align-self: stretch;
}

/* 
.label {
    border-radius: var(--br-5xs);
    background-color: var(--text-3);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-xs) var(--padding-base);
    gap: var(--gap-xs);
} */



.div,
.helpdesk {
    position: relative;
}

.helpdesk {
    flex: 1;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 24px;
}

.div {
    letter-spacing: 0.04em;
    line-height: 19px;
    font-weight: 600;
}

.pills {
    border-radius: var(--br-981xl);
    background-color: var(--primary-600);
    height: 20px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--padding-11xs) var(--padding-7xs);
    box-sizing: border-box;
    font-size: var(--caption-semibold-size);
    color: var(--base-white);
}

.icon-stroke-chevron-down {
    width: 24px;
    position: relative;
    height: 24px;
    object-fit: contain;
    display: none;
}

.menu1 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-base);
    gap: var(--gap-xs);
}

.menu1,
.menu5,
.menu6,
.pills4 {
    display: flex;
    align-items: center;
}

.pills4 {
    border-radius: var(--br-981xl);
    background-color: var(--primary-600);
    height: 20px;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-11xs) var(--padding-7xs);
    box-sizing: border-box;
    font-size: var(--caption-semibold-size);
}

.menu5,
.menu6 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.menu5 {
    border-radius: var(--br-xs);
    background-color: var(--brand-brand-primary);
    padding: var(--padding-xs) var(--padding-base);
    color: var(--base-white);
}

.menu6 {
    padding: var(--padding-xs) var(--padding-base) var(--padding-xs) var(--padding-33xl);
    color: var(--gray-500);
}

.icon-stroke-chevron-down9 {
    width: 24px;
    position: relative;
    height: 24px;
    object-fit: contain;
}

.menu11 {
    width: 248px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-xs);
}

.content,
.menu,
.top {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.menu,
.top {
    flex: 1;
}

.menu {
    display: flex;
    justify-content: flex-start;
    padding: 0 var(--padding-base);
    text-align: left;
    color: var(--gray-400);
}

.top {
    background-color: var(--text-2);
    box-shadow: 2px 6px 3px -1px rgba(6, 25, 56, 0.07);
    padding: var(--padding-21xl) 0 var(--padding-xl);
    gap: var(--gap-21xl);
}

.menu16,
.sidebar,
.top {
    display: flex;
    justify-content: flex-start;
}

.menu16 {
    align-self: stretch;
    background-color: var(--typography-body);
    flex-direction: row;
    align-items: center;
    padding: var(--padding-base) var(--padding-5xl);
    gap: var(--gap-xs);
    text-align: left;
    color: var(--gray-300);
}

.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    height: 1078px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.dashboard {
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.dashboard-wrapper,
.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.heading {
    width: 581px;
    gap: var(--gap-5xl);
}



.actions,
.nav-item-button,
.nav-item-button1 {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.nav-item-button {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    display: none;
    padding: var(--padding-3xs);
}

.actions,
.nav-item-button1 {
    display: flex;
}

.nav-item-button1 {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    padding: var(--padding-3xs);
}

.actions {
    gap: var(--gap-9xs);
}

.avatar-icon {
    width: 40px;
    border-radius: var(--br-181xl);
    height: 40px;
    object-fit: cover;
}

.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sarah-kline {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--Typography-Body, #344054);
    font-size: 14px;
    font-style: normal;
}

.content2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--text-xs-regular-size);
}

.container1,
.header-navigation {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.container1 {
    align-self: stretch;
    height: 72px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 var(--padding-13xl);
}

.header-navigation {
    position: absolute;
    top: 0;
    left: 280px;
    background-color: var(--base-white);
    border-bottom: 1px solid var(--brand-divider);
    width: 1160px;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--text-xl-semibold-size);
    color: var(--typography-subheading);
}

.all-messages {
    position: relative;
    display: flex;
    align-items: center;
}

.all-messages img {
    margin-right: 15px;
}

.all-messages p {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0px;
    color: rgba(152, 162, 179, 1);
}

.all-messages-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all-messages-parent .button {
    background-color: var(--brand-brand-primary);
    box-shadow: 0 6px 30px rgba(42, 55, 119, 0.06);
    overflow: hidden;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 16px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    align-content: center;
}

.all-messages-parent {
    align-self: stretch;
    justify-content: space-between;
    padding: 0 var(--padding-5xl);
}

.contacts-child {
    align-self: stretch;
    position: relative;
    border-top: 1px solid var(--brand-divider);
    box-sizing: border-box;
    height: 1px;
}



.div16,
.ellipse {
    position: relative;
}

.div16 {
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--Typography-Muted, #98A2B3);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 10px;
    font-style: normal;
}

.ellipse {
    width: 6px;
    background-color: var(--brand-brand-primary);
    height: 6px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.contact1 {
    width: 317px;
    border-radius: var(--br-xs);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-6xs);
}

.avatar1 {
    width: 48px;
    position: relative;
    border-radius: var(--br-181xl);
    height: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.contact2 {
    width: 317px;
    border-radius: var(--br-xs);
    background-color: var(--secondary-50);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-6xs);
}

.avatar-icon1 {
    width: 48px;
    border-radius: var(--br-181xl);
    height: 48px;
    object-fit: cover;
}

.wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
    font-size: 10px;
    color: var(--gray-400);
}


.contact,
.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.contact {
    padding: 0 var(--padding-5xl);
    gap: var(--gap-base);
    color: var(--typography-body);
    overflow-x: hidden;
    overflow-y: auto;
}



.member {
    position: relative;
    font-size: var(--font-size-xs);
    line-height: 22px;
    font-weight: 500;
    overflow: hidden;
    color: var(--Typography-Muted, #98A2B3);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
}

.avatar-parent6 {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
}

.chatBack {
    cursor: pointer;
    display: none;
}

.more-icon,
.user-parent .user {
    width: 141px;
    position: relative;
    height: 48px;
}

.more-icon {
    width: 25px;
    height: 25px;
}

.user-header,
.user-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-header {
    border-bottom: 1px solid #EAECF0;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0px 32px;
    height: 96px;
}

.friday-january-26th,
.hihow-are-things {
    position: relative;
    line-height: 22px;
    font-weight: 500;
}

.friday-january-26th {
    text-align: center;
    color: var(--Typography-Muted, #98A2B3);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.hihow-are-things {
    flex: 1;
    color: var(--Base-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.hihow-are-things-with-our-ill-wrapper .hihow-are-things {
    color: #344054;
}

.hihow-are-things-with-our-ill-wrapper {
    width: fit-content;
    align-self: stretch;
    border-radius: var(--br-xs);
    background: rgba(240, 244, 246, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-base);
}

.message,
.message1,
.wrapper3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.wrapper3 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-base);
    font-size: var(--font-size-3xs);
    color: var(--gray-400);
}

.message,
.message1 {
    flex-direction: column;
    gap: var(--gap-9xs);
}

.message1 {
    max-width: 60%;
}

.message {
    flex-direction: row;
    gap: var(--gap-5xl);
}

.hihow-are-things1 {
    position: relative;
    line-height: 22px;
    font-weight: 500;
}

.hihow-are-things-with-our-ill-container {
    border-radius: var(--br-xs);
    background-color: var(--secondary-100);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-base);
}

.message2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 var(--padding-53xl);
}

.mask-group-icon,
.mask-group-icon1 {
    width: 192.25px;
    position: relative;
    height: 173.28px;
    object-fit: cover;
}

.mask-group-icon1 {
    object-fit: contain;
    display: none;
}

.message6 {
    width: 405.5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-2xl);
}

.wrapper5 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-base);
}

.div24,
.message4,
.wrapper5 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.message4 {
    flex-direction: row;
    padding: 0 0 0 var(--padding-53xl);
    font-size: var(--font-size-3xs);
    color: var(--gray-400);
}

.div24 {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xs);
    color: var(--typography-body);
}

.hi-im-working-on-the-final-sc-wrapper {
    border-radius: var(--br-xs);
    background-color: var(--brand-brand-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-base);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
}

.message8,
.wrapper6 {
    display: flex;
    align-items: flex-start;
}

.wrapper6 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 var(--padding-base);
    font-size: var(--font-size-3xs);
    color: var(--gray-400);
}

.message8 {
    max-width: 60%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    gap: var(--gap-9xs);
}

.friday-january-26th-parent,
.message7 {
    align-self: stretch;
    display: flex;
    align-items: center;
    gap: var(--gap-5xl);
}

.message7 {
    flex-direction: row;
    justify-content: flex-end;
    color: var(--base-white);
    position: relative;
}

.friday-january-26th-parent {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px var(--padding-13xl);
    overflow-y: auto;
    /* margin-bottom: 15px; */
}

.label1,
.search-md-icon {
    position: relative;
    display: none;
}

.label1 {
    width: 95px;
    line-height: 24px;
    font-weight: 500;
}

.search-md-icon {
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text9 {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    height: 30px;
    background-color: transparent;
    border: none;
}

.content3 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.content3 textarea::placeholder {
    color: var(--Gray-400, #98A2B3);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.content3 textarea {
    background-color: transparent;
    border: none;
}

.content3 textarea:focus-visible {
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.input,
.input-with-label {
    align-self: stretch;
    display: flex;
}

.input {
    border-radius: var(--br-xs);
    background-color: var(--secondary-25);
    box-shadow: var(--shadow-xs);
    height: 46px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-5xs);
    color: var(--gray-400);
}

.input-with-label {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-7xs);
}

.hint-text {
    width: 320px;
    position: relative;
    line-height: 24px;
    color: var(--gray-600);
    display: none;
}

.input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-7xs);
}

.avatar-parent7,
.chat2 {
    align-self: stretch;
    align-items: center;
}

.avatar-parent7 {
    background-color: var(--base-white);
    border-top: 1px solid #EAECF0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-13xl) var(--padding-13xl);
    gap: 14px;
    font-size: var(--text-xs-regular-size);
    color: var(--typography-body);
}

.chat2 {
    flex: 1;
    flex-direction: column;
    font-size: var(--font-size-xs);
    color: var(--gray-400);
    position: relative;
    height: calc(100vh - 140px);
    background-color: rgb(255, 255, 255);
    overflow-y: hidden;
    overflow-x: hidden;
}

.chat,
.chat1,
.chat2 {
    display: flex;
    justify-content: flex-start;
}

.chat1 {
    align-self: stretch;
    flex: 1;
    border-radius: 0 var(--br-mini) var(--br-mini) 0;
    background-color: white;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    color: var(--typography-body);
}

.message-center-admin {
    width: 100%;
    position: relative;
    background-color: var(--brand-background-card-light);
    height: 1078px;
    overflow: hidden;
    text-align: left;
    font-size: var(--text-xs-regular-size);
    color: var(--text-7);
    font-family: var(--text-md-regular);
}


.div-1 {
    display: flex;
    padding-right: 80px;
    gap: 12px;
    font-size: 14px;
    line-height: 24px;
}

.div-2 {
    color: var(--Typography-Muted, #98a2b3);
    font-family: MyCustomFont, sans-serif;
    font-weight: 600;
    margin: auto 0;
}

.div-3 {
    align-items: center;
    border-radius: 16px;
    background-color: var(--Gray-100, #f2f4f7);
    display: flex;
    gap: 6px;
    color: var(--Gray-700, #344054);
    font-weight: 500;
    text-align: center;
    padding: 2px 9px 2px 8px;
}

.div-3 img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 16px;
    align-self: stretch;
    margin: auto 0;
}

.div-4 {
    font-family: "MyCustomFont";
    align-self: stretch;
    text-wrap: nowrap;
}

.img-2 {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 12px;
    align-self: stretch;
    margin: auto 0;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-content {
    top: 65px;
    max-height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 16px 10px;
    z-index: 1;
    box-shadow: 0px 0px 30px 0px rgba(122, 122, 122, 0.14);

}

.dropdown-content .item {
    cursor: pointer;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    padding: 12px 6px;
    text-decoration: none;
    margin-bottom: 5px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.dropdown-content .item:hover {
    background-color: rgba(244, 247, 248, 1);
}

.chat .dropdown-content .item .label {
    color: var(--Typography-Body, #344054);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.dropdown-content .item .label {
    align-items: center;
    margin-left: 12px;
    width: 144px;
}

.dropdown-content .item img {
    width: 32px;
    height: 32px;
}

.u1div {
    align-items: center;
    display: flex;
    max-width: 149px;
    flex-direction: column;
    text-align: center;
    padding: 24px 0;
}

.u1div .avatar-icon1 {
    width: 115px;
    height: 115px;
}

.u1img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 115px;
    max-width: 115px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.messageImage {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.u1div-2 {
    color: var(--Typography-Body, #344054);
    font-variant-numeric: lining-nums proportional-nums;
    align-self: stretch;
    margin-top: 24px;
    width: 100%;
    font: 600 18px/156% MyCustomFont, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.u1div-3 {
    color: var(--Typography-Muted, #98a2b3);
    margin-top: 4px;
    font: 400 14px/24px MyCustomFont, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.dropdown-content::-webkit-scrollbar {
    width: 4px;
}

.dropdown-content::-webkit-scrollbar-track {
    background: white;
}

.dropdown-content::-webkit-scrollbar-thumb {
    background: #D0D5DD;
    border-radius: 10px;
    border: none;
}

.u2div {
    align-items: center;
    display: flex;
    max-width: 218px;
    flex-direction: column;
    padding: 24px 0;
}

.u2div-2 {
    justify-content: center;
    display: flex;
    gap: 16px;
}

.u2img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 63px;
}

.u2img-2 {
    aspect-ratio: 0.98;
    object-fit: auto;
    object-position: center;
    width: 62px;
}

.u2div-3 {
    color: var(--Typography-Body, #344054);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    align-self: stretch;
    margin-top: 24px;
    width: 100%;
    font: 600 18px/156% MyCustomFont, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.u2div-4 {
    color: var(--Typography-Muted, #98a2b3);
    text-align: center;
    margin-top: 4px;
    font: 400 14px/24px MyCustomFont, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.mobileNewMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: var(--br-mini) 0 0 var(--br-mini);
    background-color: white;
    border-right: 2px solid #EAECF0;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.mobileNewMessage .mobileMessageHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid rgba(234, 236, 240, 1)
}
.mobileNewMessage .mobileMessageHead .chatBack{
    font-size: 19px;
    color: rgba(29, 41, 57, 1);
}

.mobileNewMessage .mobileMessageHead p {
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    color: rgba(29, 41, 57, 1);
}

.mobileNewMessage .mobileMessengerList {
    width: 100%;
    padding: 16px 20px;
}

.mobileMessengerList .mobileChat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.mobileChat .chatItem {
    width: 100%;
    display: flex;
    align-items: center;
}

.mobileChat .chatItem img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.mobileChat .chatItem p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.mobileNewMessage .mobileMessengerList .mobileToUser {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.mobileMessengerList .mobileToUser .div-3,.mobileNewMessage .mobileMessengerList .mobileToUser span {
    margin-right: 15px;
    margin-top: 10px;
}

.mobileMessengerList .mobileCreate {
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    background: #6366F1;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 16px 18px;
    text-align: center;
    width: 100%;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-decoration: none;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-messages-parent-mobile{
    display: none;
}
.chatHeading{
    display: none;
}
.chatHeading h6{
    font-family: 'MyCustomFont';
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #101828;
  margin-bottom: 20px;
}

@media (max-width: 800px) {
    .chatHeading{
        display: block;
    }
   .mobileFilesHeader .searchInput{
    width: 100%;
    margin-right: 5px;
   }
    .contacts {
        width: 100%;
        border-right: none;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
    }

    .chatBack {
        cursor: pointer;
        display: block;
        font-size: 18px;
        margin-right: 15px;
    }

    .all-messages-parent{
        display: none;
    }

    .all-messages-parent-mobile{
        display: none;
        padding: 8px 4px 20px;
    }

    .all-messages-parent-mobile p{
        font-size: 18px;
        font-family: 'MyCustomFont';
        font-weight: 600;
    }

    .all-messages-mobile img{
        width: 44px;
        height: 44px;
    }

    .all-messages-mobile input{
        width: 100%; 
        font-size: 14px;
        height: 44px;
        padding: 15px;
        border: none;
        border-radius: 10px;
    }

    .all-messages-mobile{
        position: relative;
    }

    .all-messages-mobile img{
        position: absolute;
        width: 20px;
        right: 16px;
    }

    .contact-mobile{
        padding: 16px;
    }

    .mainContentMobile{
        padding: 0px;
    }
    .avatar-parent7{
padding: 20px 12px;
    }
    .mobileMessengerList h6{
        font-family: 'MyCustomFont';
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #101828;
        margin-bottom: 20px;
    }
}