.error-boundary {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
};

.error-boundary h2 {
  color: "#555";
}