.memberHome h5 {
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.memberHome h5 span {
    font-family: "MyCustomFont";
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.memberHome h5 img,
.wifiQr h5 img {
    margin-right: 10px;
}

.memberDashboard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.memberDashboard .memberLeft {
    width: 69%;
}

.memberDashboard .memberRight {
    width: 29%;
}

.memberLeft .assetsTicket {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 23px 44px 0px rgba(176, 183, 195, 0.14);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberLeft .assetsTicket .ticketItem {
    width: 49%;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(248, 250, 251, 1);
    padding: 16px 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberLeft .assetsTicket .ticketItem p {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 0px;
}

.memberLeft .assetsTicket .ticketItem p img {
    margin-right: 14px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.memberLeft .assetsTicket .ticketItem button {
    background-color: transparent;
    color: rgba(80, 82, 201, 1);
    border: none;
}

.memberLeft .latestInvoice {
    margin-top: 20px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 23px 44px 0px rgba(176, 183, 195, 0.14);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.latestInvoice .latestInvoiceHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    border-bottom: 1px solid #EAECF0;
}

.latestInvoice .latestInvoiceHeading img {
    margin-right: 10px;
}

.latestInvoice .latestInvoiceHeading h5,
.wifiQr h5 {
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 0px;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 1.2rem;
}

.latestInvoice .latestInvoiceHeading button,
.latestInvoice .latestInvoiceHeading a {
    background-color: #fff;
    border: none;
    font-family: "MyCustomFont";
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: rgba(99, 102, 241, 1);
    text-decoration: none;
}

.latestInvoice .billingList {
    height: auto !important;
}

.invoiceBillingList table thead tr th {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #475467;
    height: 56px;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #F4F7F8;
}

.invoiceBillingList table tbody tr td:nth-child(1),
.invoiceBillingList table thead tr th:nth-child(1) {
    padding-left: 24px;
}

.invoiceBillingList table tbody tr td:nth-child(6),
.invoiceBillingList table thead tr th:nth-child(6) {
    padding-right: 24px;
}

.invoiceBillingList table tbody tr td:nth-child(1) {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.invoiceBillingList table tbody tr td:nth-child(2),
.invoiceBillingList table tbody tr td:nth-child(3),
.invoiceBillingList table tbody tr td:nth-child(4),
.invoiceBillingList table tbody tr td:nth-child(5),
.invoiceBillingList table tbody tr td:nth-child(6) {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
}

.memberRight .wifiQr,
.memberRight .upcomingBook {
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.memberRight .wifiQr .qr {
    width: 100%;
    padding: 24px;
}

.memberRight .wifiQr .qr img {
    width: 100%;
}

.memberRight .wifiQr .qr p {
    margin-top: 10px;
    font-family: "MyCustomFont";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.memberRight .wifiQr .qr p span {
    font-weight: 700;
}


.upcomingBook .bookingList {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.upcomingBook .bookingList img {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.upcomingBook .bookingList .spacesInfo {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    padding: 0px;
}

.upcomingBook .bookingList .spacesInfo h6 {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 0px;
}

.upcomingBook .bookingList .spacesInfo p {
    font-family: "MyCustomFont";
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 0px;
}

#my-home .upcomingBook .bookingList {
    border-radius: 12px;
    background: var(--Secondary-25, #F8FAFB);
}

.latestInvoice .activeAssign {
    padding: 0px;
    border: none;
}

.wifiInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(208, 213, 221, 1);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.wifiInfo span {
    min-width: 100px;
    padding: 11px 12px;
    border-right: 1px solid rgba(208, 213, 221, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(71, 84, 103, 1);
}

.wifiInfo input {
    border: none;
}

.wifiInfo input:focus-visible {
    outline-offset: none;
    border-color: none;
    box-shadow: none;
}

.wifiInfo button {
    background-color: transparent;
    border: none;
    padding: 0px 15px;
}

.wifiInfo button img {
    width: 16px !important;
    height: 16px !important;
}

.pendingInvoice .balance span {
    color: rgba(180, 35, 24, 1);
}

.pendingInvoicesDue {
    display: flex;
    justify-content: space-between;
}

.pendingInvoice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #EAECF0;
}

/* unread message chat */
.messageChatBox{
    padding: 24px;
  background-color: #fff;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.messageChatBox .messageHeading{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 1.2rem;
    margin-bottom: 10px;
}
.messageChatBox h5{
    font-family: "MyCustomFont";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.messageChatBox .messageHeading a{
    font-family: "MyCustomFont";
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: rgba(99, 102, 241, 1);
}

@media (max-width: 800px) {

    .memberDashboard .memberLeft,
    .memberDashboard .memberRight {
        width: 100%;
    }

    .memberLeft .assetsTicket .ticketItem button {
        display: none;
    }

    .memberLeft .assetsTicket .ticketItem {
        justify-content: center !important;
    }

    .memberLeft .assetsTicket .ticketItem p {
        text-align: center;
    }

    .memberLeft .assetsTicket .ticketItem p span {
        display: block;
        margin-top: 10px;
    }

    .memberAssign .invoiceHeading .invoiceName {
        flex-direction: column;
    }

    .memberAssign .invoiceHeading .invoicePrice {
        flex-direction: column-reverse;
    }

    .activeAssign .deskType span {
        margin-top: 30px;
        display: block;
    }

    .memberAssign.activeAssign .invoiceHeading .invoicePrice p {
        margin-top: 30px;
        display: block;
        margin-right: 0px;
        padding-right: 0px;
        border-right: none;
    }

    .memberRight .wifiQr .qr {
        padding: 16px 0px 0px 0px;
    }

    .mobileInvoices .invoiceBox .invoiceDetails {
        flex-direction: column;
    }

    .assignMobile {
        border-bottom: 1px solid rgba(234, 236, 240, 1);
    }

    .pendingInvoicesDue {
        margin-top: 15px;
        justify-content: space-between;
    }

    .pendingInvoicesDue .assign {
        text-align: left;
        width: 45%;
    }

    .invoiceHistory .invoiceBox {
        padding: 16px;
    }

    .assignMobile {
        padding-bottom: 10px;
    }
.messageChatBox{
    margin-top: 16px;
}
}