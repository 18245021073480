.loading-button-right {
  margin-top: 24px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6366F1;
  width: 130px;
  height: 48px;
  border-radius: 12px;
}

.loading-button-right button {
  background-color: #6366F1;
  width: 30px;
  height: 30px;
  border: 2px solid #fff; 
  border-top: 2px solid #6366F1;
  border-radius: 50%;
  animation: spin .5s linear infinite;
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: 2px solid #6366F1;
  border-top: 2px solid transparent; 
  border-radius: 50%;
  animation: spin .3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.cursor-pointer {
  cursor: pointer;
};