.doorSection .doorHeading {
    font-family: 'MyCustomFont';
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(16, 24, 40, 1);
    margin-bottom: 2px;
}

.doorSection .lock {
    background: rgba(254, 243, 242, 1);
    color: rgba(180, 35, 24, 1);
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}
.doorSection .unlock{
    background:rgba(236, 253, 243, 1);
    color: rgba(2, 122, 72, 1);
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}