.line-chart-up-04-icon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.postingAs {
    border: none !important;
    padding: 20px 6px !important;
    box-shadow: 0px 12px 30px 0px rgba(122, 122, 122, 0.14) !important;
}

.postingAs a {
    /* margin-top: 8px; */
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    padding: 10px 10px;
    border-radius: 4px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.postingAs a img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.postingAs a.admin img {
    border: 2px solid rgba(255, 171, 0, 1) !important;
}

.trashPost .postDelete {
    border-radius: 12px;
    background: var(--Base-White, #FFF);
    border: none;
    padding: 28px 0;
    box-shadow: 0px 0px 30px 0px rgba(122, 122, 122, 0.14);
}

.trashPost .postDelete a img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.trashPost .postDelete a {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    display: flex;
    align-items: center;
}

.custom-dropdown-toggle {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: unset;
}

.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:active {
    background: none !important;
    box-shadow: none !important;
    /* Remove focus/hover/active shadow */
}

.message-emoji img {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.icon-stroke-chevron-down {
    width: 24px;
    position: relative;
    height: 24px;
    object-fit: contain;
    display: none;
}

.commentInput img {
    cursor: pointer;
}

.commentInput .commentBox {
    background-color: rgba(248, 250, 251, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    max-height: 100px;
    min-height: 20px;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 1;
    width: 100%;
    user-select: text;
    border: none;
    padding: 9px 12px 11px;
    text-align: left;
    margin: 0px 10px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.commentInput .commentEmoji {
    background-color: #fff;
    border: none;
    margin-right: 10px;
    font-size: 18px;
}




.icon-stroke-chevron-down7 {
    width: 24px;
    position: relative;
    height: 24px;
    object-fit: contain;
}

.content,
.menu,
.top {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.menu,
.top {
    flex: 1;
}

.menu {
    display: flex;
    justify-content: flex-start;
    padding: 0 var(--padding-base);
}

.top {
    background-color: var(--text-2);
    box-shadow: 2px 6px 3px -1px rgba(6, 25, 56, 0.07);
    padding: var(--padding-21xl) 0 var(--padding-xl);
    gap: var(--gap-21xl);
}


.info-circle-icon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.actions,
.nav-item-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.nav-item-button {
    border-radius: var(--br-7xs);
    background-color: var(--bg-4);
    overflow: hidden;
    padding: var(--padding-3xs);
}

.actions {
    gap: var(--gap-9xs);
}

.avatar-icon {
    width: 40px;
    border-radius: var(--br-181xl);
    height: 40px;
    object-fit: cover;
}

.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.elviro-anasta {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    font-size: 14px;
}

.content1,
.content2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content2 {
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--text-xs-regular-size);
}

.content1 {
    align-self: stretch;
    justify-content: space-between;
}


.header-navigation {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}


.vector-icon {
    width: 20px;
    position: relative;
    height: 20px;
    margin: 8px;
    object-fit: cover;
}

.postLogo {
    border-radius: var(--br-71xl);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.mins-ago {
    position: relative;
    font-size: var(--font-size-xs);
    line-height: 22px;
    font-weight: 500;
    color: var(--gray-400);
    font-size: 12px;
}

.beehive-coworking-parent,
.user {
    display: flex;
    justify-content: flex-start;
}

.beehive-coworking-parent {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.user {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-base);
}

.wtqzczkosgc-1-icon {
    width: 100%;
    position: relative;
    height: auto;
    object-fit: cover;
}

.images {
    align-self: stretch;
    border-radius: var(--br-xs);
    background-color: var(--secondary-25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(248, 250, 251, 1);
}

.images img {
    max-height: 260px;
    cursor: pointer;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.heart-icon {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.like {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
    cursor: pointer;
}

.feedback-child {
    align-self: stretch;
    width: 1px;
    position: relative;
    border-right: 1px solid var(--brand-divider);
    box-sizing: border-box;
}

.comments {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    font-size: 12px;
}

.feedback {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: end;
    gap: var(--gap-13xl);
    font-size: var(--font-size-xs);
    color: var(--gray-400);
}

.label,
.search-md-icon {
    position: relative;
    display: none;
}

.label {
    width: 95px;
    line-height: 24px;
    font-weight: 500;
}

.search-md-icon {
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 500;
}

.content3 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.content3 input {
    border: none;
    font-family: 'MyCustomFont';
    line-height: 24px;
    font-weight: 400;
    color: #98A2B3;
    background-color: var(--secondary-25);
}

.content3 input:focus,
.content3 input:focus-visible {
    color: none !important;
    background-color: var(--secondary-25);
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}



.input {
    border-radius: var(--br-xs);
    background-color: var(--secondary-25);
    box-shadow: var(--shadow-xs);
    height: 46px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-base);
    box-sizing: border-box;
    gap: var(--gap-5xs);
    color: var(--gray-400);
}

.input-with-label {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-7xs);
}


.input-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-7xs);
}

.avatar-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--typography-body);
    position: relative;
}

.commentInput aside {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 435px;
    width: 352px;
    overflow: hidden;
    z-index: 10;
}

.commentShow {
    width: 100%;
}

.commentShow .avatar-parent {
    margin-left: 45px;
    margin-top: 8px;
}

.avatar-parent svg {
    color: var(--gray-400);
}

.default {
    background-color: var(--gray-100);
    padding: 5px;
}

.avatar-parent .commentInput {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 15px;
    margin-left: 1rem;
    background-color: rgba(248, 250, 251, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.avatar-parent .commentInput p {
    font-size: 14px;
    font-weight: 800;
    line-height: 24px;
    text-align: right;
    color: rgba(16, 24, 40, 1);
    text-wrap: nowrap;
    margin-bottom: 0px;
}

.avatar-parent .commentInput .commentImg {
    border-left: 1px solid rgba(234, 236, 240, 1);
    border-right: 1px solid rgba(234, 236, 240, 1);
    text-wrap: nowrap;
    display: flex;
    padding: 0px 8px;
    margin-right: 5px;
}


.react-input-emoji--input,
.react-input-emoji--placeholder {
    font-family: "MyCustomFont" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: rgba(152, 162, 179, 1) !important;
    text-align: left;
    background-color: rgb(248, 250, 251) !important;
}

.post {
    width: 664px;
    border-radius: var(--br-mini);
    background-color: var(--bg-4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-lgi) var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-5xl);
    position: relative;
    border: 1px solid rgba(234, 236, 240, 1)
}

.nvett22elcu-1-icon {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.images2,
.user2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.images2 {
    width: 260px;
    height: 260px;
    align-items: flex-end;
}

.user2 {
    align-self: stretch;
    align-items: center;
    gap: var(--gap-base);
    color: var(--typography-subheading);
}

.the-modern-workplace {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    color: #344054;
    font-size: 14px;
    font-family: 'MyCustomFont';
}

.avatar-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-sm);
}

.post2 {
    width: 664px;
    border-radius: var(--br-mini);
    background-color: var(--bg-4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-lgi) var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-5xl);
    color: var(--typography-body);
}

.input-field3,
.input-with-label3,
.input3,
.post-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.post-parent {
    margin-top: 24px;
    flex-direction: column;
    gap: var(--gap-5xl);
}

.input-field3,
.input-with-label3,
.input3 {
    flex: 1;
}

.input3 {
    align-self: stretch;
    background-color: var(--secondary-25);
    box-shadow: none;
    overflow: hidden;
    flex-direction: row;
    gap: var(--gap-5xs);
    color: var(--gray-400);
}

.input3 textarea {
    align-self: stretch;
    background-color: rgba(248, 250, 251, 1);
    overflow: hidden;
    flex-direction: row;
    padding: 0;
    /* padding: var(--padding-3xs) var(--padding-base); */
    gap: var(--gap-5xs);
    color: var(--gray-400);
    width: 100%;
    border: none;
    font-size: 14px;
    line-height: 24px;
    font-family: 'MyCustomFont';
}

.input3 textarea::placeholder {
    color: var(--gray-400);
}

.input-field3 {
    flex-direction: column;
    gap: var(--gap-7xs);
}

.input-with-label3 {
    align-self: stretch;
    display: flex;
    background-color: rgba(248, 250, 251, 1);
    gap: 8px;
    padding: 10px 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.input-field3 {
    height: 76px;
}

.frame-div {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-sm);
}

.frame-div,
.image,
.new-post {
    display: flex;
    justify-content: flex-start;
}

.postIconImage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
}

.image {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
    cursor: pointer;
    position: relative;
}


.new-post,
.postIconImage {
    align-items: flex-start;
    gap: var(--gap-5xl);
}

.postIconImage {
    flex-direction: row;
    font-size: var(--font-size-xs);
    color: var(--gray-400);
    align-items: center;
}

.postIconImage button,
.postIconImage button:hover {
    height: 44px;
    background: #6366F1;
    box-shadow: 0px 6px 30px 0px #2A37770F;
    padding: 12px 16px;
    border: none;
    font-family: 'MyCustomFont';
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.postIconImage button img {
    margin-right: 8px;
}

.trashPost .dropdown .dropdown-toggle {
    background-color: #fff;
    border: none;
}

.trashPost .dropdown .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: none !important;
    border-right: none !important;
    border-bottom: 0;
    border-left: none !important;
}

.new-post {
    border-radius: var(--br-mini);
    background-color: var(--bg-4);
    box-shadow: 0 1px 5px rgba(176, 183, 195, 0.14);
    width: 664px;
    flex-direction: column;
    padding: var(--padding-5xl);
    box-sizing: border-box;
    color: var(--typography-body);
    border: 1px solid rgba(234, 236, 240, 1);
}

.announcementAdmin {
    position: relative;
    text-align: left;
    font-size: var(--text-xs-regular-size);
    color: var(--typography-subheading);
    font-family: var(--text-xs-regular);
}

/* .rightNavbar {
    display: flex;
    align-items: center;
} */

/* .postIconImage button {
    background-color: transparent;
    margin: 0px 5px;
    border: none;
} */

.postIconImage .dropdown button {
    height: 44px;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    padding: 12px 16px !important;
    text-align: left;
    color: #98A2B3;
    background-color: transparent;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    display: flex;
    align-items: center;
}

.postIconImage .dropdown button:hover {
    background-color: #fff;
}

.postIconImage .dropdown-toggle::after {
    display: none !important;
}

.postIconImage .dropdown-toggle img {
    margin-left: 10px;
}

.commentReply {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.comment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: first baseline;
}

.commentReply {
    padding-left: 54px;
    margin-top: 18px;
    position: relative;
}

/* .commentReply::before,
.commentReply::after {
    content: "";
    position: absolute;
    width: 22px;
    bottom: 17px;
    left: 17px;
    border-left: 1px solid rgba(208, 213, 221, 1);
} */

.commentReply::before {
    content: "";
    position: absolute;
    width: 22px;
    height: 30px;
    left: 17px;
    border-left: 1px solid rgba(208, 213, 221, 1);
}


.commentReply::before {
    top: -2px;
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    border-bottom-left-radius: 14px;
}

.commentReply::after {
    bottom: -2px;
}

.commentText {
    flex-grow: 1;
    padding: 0px 15px;
}

.commentText h6 {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin-bottom: 0px;
}

.commentText h6 span {
    font-family: "MyCustomFont";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.commentText p {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    margin-bottom: 0px;
}

.newComment {
    display: flex;
}

.newComment button {
    background-color: #fff;
    border: none;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: rgba(152, 162, 179, 1);
}

.newComment button:first-child {
    border-left: 1px solid rgba(234, 236, 240, 1);
    border-right: 1px solid rgba(234, 236, 240, 1);
}

#postTextarea {
    resize: none;
    /* Disable manual resizing */
    overflow: hidden;
    /* Hide overflow content */
    min-height: 50px;
    /* Set minimum height */
    width: 100%;
    /* Occupy full width */
}

.react-input-emoji--button svg {
    width: 20px !important;
}

.react-input-emoji--container {
    border-color: transparent !important;
}

.reply-comment button {
    display: flex;
    gap: 5px
}

.comment-time-mobile,
.comment-time-desktop {
    font-size: 12px;
    color: #98A2B3
}

.comment-time-mobile {
    display: none;
}

@media (max-width: 778px) {
    .new-post {
        width: 100%;
    }

    .comment-time-desktop {
        display: none;
    }

    .comment-time-mobile {
        display: block;
    }

    .reply-text {
        margin-top: 20px;
    }

    .post {
        width: 100%;
    }

    .images {
        padding: 15px;
    }

    .feedback {
        justify-content: space-between;
        padding: 0px 15px;
    }

    .post {
        padding: 15px;
    }

    .react-input-emoji--button {
        display: none !important;
    }

    .postIconImage .dropdown {
        flex: 1
    }

    .postIconImage button {
        justify-content: center;
        font-weight: 800;
        width: 100%;
    }

    .postIconImage span {
        padding-top: 3px;
    }

    .postIconImage {
        gap: 16px;
        padding: 0;
    }

    .postIconImage .dropdown button {
        font-weight: 800;
    }

    #postTextarea::placeholder {
        font-weight: 800;
    }

    .heart-icon {
        width: 25px;
    }

    .postDelete.dropdown-menu {
        margin-top: 0 !important;
        padding: 12px 0 !important;
        border: 1px solid rgba(234, 236, 240, 1);
    }

    .addMemberForm .closeModal {
        right: 0;
        top: -16px;
    }

    .uploadBtn button {
        width: 100%;
    }

    .drop-file-input {
        padding: 150px 24px;
    }

    .commentReply {
        margin-bottom: 10px;
    }
}