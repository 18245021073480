.visitorLink{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(208, 213, 221, 1);
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.visitorLink .visitorLinkUrl{
display: flex;
flex-direction: column;
flex-grow: 1;
padding-left: 15px;
}
.visitorLink .visitorLinkUrl h5{
    font-family: "MyCustomFont";
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
margin-bottom: 4px;
color: rgba(52, 64, 84, 1);
}

.visitorLink .visitorLinkUrl h6{
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    margin-bottom: 0px;
}
.visitorLink .visitorLinkUrl h6 img{
    cursor: pointer;
}
.visitorLink svg{
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    cursor: pointer;
}

.visitorTableList .memberList{
    height: calc(100vh - 332px) !important;
}