.resourcesPage {
  display: flex;
  /* Specificity: (0, 1, 0) */
}

.upcomingListResources {
  background-color: #fff;
  min-width: 40%;
  margin-left: 30px;
  padding: 24px;
  border-radius: 16px;
  /* border: 1px solid #eaecf0; */
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

#resources .spaceList table tbody tr td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.resourceType span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: auto;
  padding: 5px 10px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.resourceType span.meeting {
  color: rgba(23, 92, 211, 1);
  background: rgba(239, 248, 255, 1);
}

.resourceType span.equipment {
  color: rgba(99, 102, 241, 1);
  background: rgba(239, 240, 254, 1);
}

.resourceType span.workspace {
  color: rgba(193, 21, 116, 1);
  background: rgba(253, 242, 250, 1);
}

.resourceType span.other {
  color: rgba(185, 56, 21, 1);
  background: rgba(255, 244, 237, 1);
}

.mobileAllResource {
  display: none;
}

.pcMobileResource {
  display: block;
}

.mobileAssets .resourceType {
  display: none;
}

.mobileResourceBtn .resourceType {
  display: block;
}

.publicAssets .paymentInfo {
  margin-left: 0px;
}

.publicAssets .tabResourcePanel .tabHeading {
  border-right: none;
}

.publicAssets .paymentDetails .vertical-bar {
  margin-left: 10px;
  margin-right: 10px;
}

.publicAssets .chooseTime div {
  min-width: 130px;
}

.publicAssets .chooseTime div p {
  font-size: 14px;
}

.publicAssets .timeDropdown .dropdown .dropdown-toggle {
  width: 115px;
}

.publicAssets .resourcePay {
  padding: 24px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.publicAssets .resourcePrice {
  margin-top: 16px;
}

@media screen and (max-width: 800px) {


  .bookingHeading h6 {
    font-family: "MyCustomFont";
  }

  .serc {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .serc button {
    font-size: 14px;
    font-weight: 600;
    color: black !important;
    width: 120px !important;
    height: 40px !important;
  }

  .rscT span {
    font-size: 14px !important;
    font-weight: 800;
  }

  .mobileAllResource {
    display: block;
    width: 100%;
    padding: 0px 22px;
    padding-bottom: 20px;
  }

  .pcMobileResource {
    display: none;
  }

  .resourcesPage .memberBox {
    margin-top: 16px;
  }

  .upcomingListResources {
    padding: 16px;
    margin-left: 0px;
  }

  .bookingPerson {
    padding: 12px;
  }

  .resourcesPage {
    flex-direction: column-reverse;
  }

  .mobileAssets .resourceType {
    display: block;
  }

  .mobileResourceBtn .resourceType {
    display: none;
  }

  .mobileResourceBtn .mobileAssetsBtn {
    background: #6366f1;
    padding: 11px 22px;
    border: none;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-decoration: none;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
}