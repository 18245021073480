.ConfirmationIcon {
  text-align: center;
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.ConfirmationIcon svg {
  background-color: rgb(241, 255, 241);
  color: rgba(99, 102, 241, 1);
  height: 2em !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.ConfirmationIcon .circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgb(241, 255, 241);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ConfirmationIcon h4 {
  font-family: 'MyCustomFont';
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: rgba(16, 24, 40, 1);
}

.ConfirmationIcon p {
  margin-top: 20px;
  font-family: 'MyCustomFont';
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: rgba(52, 64, 84, 1);
}

.deleteBtn {
  display: flex;
  justify-content: center;
}

.deleteBtn button {
  margin: 0px 15px;
  color: white;
  padding: 12px 18px;
  font-family: 'MyCustomFont';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  border: none;
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.deleteBtn .cancel {
  background: rgba(99, 102, 241, 1);
  color: white;
}

.deleteBtn .save {
  background: rgb(16, 192, 16);
}
