.spaceList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* height: calc(100vh - 224px); */
}

.spaceList .table-responsive {
  width: 100%;
}

.spaceList table tbody tr .rate {
  font-style: normal;
}

.spaceList table tbody tr td {
  vertical-align: middle;
}

.spaceList table tbody tr td:nth-child(1) {
  color: #101828;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.spaceList table tbody tr td:nth-child(2),
.spaceList table tbody tr td:nth-child(4),
.spaceList table tbody tr td:nth-child(5),
.spaceList table tbody tr td:nth-child(6) {
  color: #475467;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.spaceList table tbody tr td:nth-child(3) {
  color: #101828;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
}

.spaceList table tbody tr td img {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.spaceList table thead tr th:nth-child(1),
.spaceList table tbody tr td:nth-child(1) {
  width: 68px;
  text-align: center;
  padding: 0px !important;
  position: relative;
  vertical-align: middle;
}

.spaceList table thead tr th:nth-child(2),
.spaceList table tbody tr td:nth-child(2) {
  width: 96px;
  text-align: center;
  padding: 0px !important;
  position: relative;
  justify-content: center;
}

.spaceList table thead tr th:first-child,
.spaceList table tbody tr td:first-child {
  width: 68px;
}

.spaceList table tbody tr td:nth-child(8) {
  white-space: nowrap;
}

.spaceList table tbody tr td:nth-child(7) {
  text-align: center;
  white-space: nowrap;
}

/* 
.spaceList table thead tr th:nth-child(3) {
    width: 192px;
    text-align: left;
} */

.spaceList table thead tr th:nth-child(3) {
  width: 186px;
  text-align: left;
}

.spaceList table thead tr th:nth-child(4) {
  width: 188px;
  text-align: left;
}

.spaceList table thead tr th:nth-child(5) {
  width: 119px;
  text-align: left;
}

.spaceList table thead tr th:nth-child(6) {
  width: 153px;
  text-align: left;
}

.spaceList table thead tr th:nth-child(7),
.spaceList table tbody tr td:nth-child(7) {
  width: 206px;
  text-align: center;
}

.spaceList table thead tr th:nth-child(8),
.spaceList table tbody tr td:nth-child(8) {
  width: 116px;
  text-align: center;
}

.spaceList table thead tr th {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #475467;
  height: 56px;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #f9fafb;
}

.status .unavailable {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #c01048;
  background: #fff1f3;
  width: auto;
  padding: 5px 8px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.status .available {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #027a48;
  background: #ecfdf3;
  width: auto;
  padding: 5px 8px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.deskType span {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: fit-content;
  padding: 5px 8px;
  margin-bottom: 0px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.deskType .private {
  color: #175cd3;
  background: #eff8ff;
}

.deskType .dedicated {
  color: #6366f1;
  background: #eff0fe;
}

.deskType .flex {
  color: #b54708;
  background: #fffaeb;
}

.memberSearch .filterBtn {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
  margin-right: 15px;
}

.sizeInput input {
  padding-right: 65px;
}

.rate input {
  padding-right: 65px;
  padding-left: 30px;
}

.custom-toggle::after {
  display: none !important;
}

.memberSpacesList {
  display: flex;
  align-items: center;
  justify-content: center;
}

.memberSpacesList img {
  margin-left: -12px;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.plusMember {
  cursor: pointer;
  width: 24px;
  position: relative;
  background-color: #f2f4f7;
  border: 2px solid white;
  box-sizing: border-box;
  height: 24px;
  margin-left: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.tableImage {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.tableLink a {
  text-decoration: none;
  font-family: "MyCustomFont";
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  color: var(--Gray-900, #101828);
  font-style: normal;
  font-weight: 800;
}

.spacesDetailsBox {
  background-color: #fff;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.spacesDetailsBox .editBtn button {
  border: 1px solid rgba(99, 102, 241, 1) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  font-family: "MyCustomFont";
  font-size: 12px !important;
  font-weight: 600;
  line-height: 16px !important;
  text-align: left;
  color: rgba(99, 102, 241, 1) !important;
  background-color: #fff !important;
}

.spacesDetailsBox .editBtn button img {
  margin-right: 7px;
}

.spacesInfo .leftSpacesImage img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.spacesInfo {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}
.spacesInfo .leftSpacesImage {
  width: 49%;
}
.spacesInfo .rightSpacesContent {
  /* padding: 32px;
    border: 1px solid rgba(234, 236, 240, 1); */
  width: 49%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.rightSpacesContent .spacesHeadingBox {
  display: flex;
  justify-content: space-between;
}

.rightSpacesContent .spacesHeadingBox h6 {
  font-family: "MyCustomFont";
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: rgba(29, 41, 57, 1);
}

.rightSpacesContent .spacesHeadingBox h6 span {
  margin-left: 5px;
}

.rightSpacesContent .spacesDescription {
  margin-top: 20px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.rightSpacesContent .spacesMiddle {
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  width: 100%;
  display: flex;
  justify-content: center;
}

.spacesDescription .spacesSize h6,
.spacesDescription .spacesType h6,
.spacesDescription .spacesNotes h6 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}
.spacesDescription .spacesNotes p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.spacesDescription .spacesSize,
.spacesDescription .spacesType,
.spacesDescription .spacesNotes {
  padding: 16px 24px;
  width: 100%;
}

.spacesDetailsBox .assignments {
  border-top: 1px solid rgba(234, 236, 240, 1);
}

.sortArrow {
  cursor: pointer;
}

@media (max-width: 800px) {
  .mobileMember .bookingPerson button:first-of-type {
    width: auto;
  }
  .assetsPc {
    display: none;
  }
  .spacesInfo {
    flex-direction: column-reverse;
  }
  .spacesInfo .rightSpacesContent {
    width: 100%;
    margin-top: 25px;
  }
  .spacesInfo .leftSpacesImage {
    width: 100%;
  }
}
