.mobileInvoice {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.mobileInvoice .mobileInvoiceHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileInvoice .mobileInvoiceHeading a {
  font-family: "MyCustomFont";
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin-bottom: 0px;
}

.mobileInvoice .mobileInvoiceInfo p {
  margin-top: 20px;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  color: rgba(71, 84, 103, 1);
}

.mobileInvoice .mobileInvoiceInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobileInvoice .mobileInvoiceInfo p {
  flex-grow: 1;
}

.mobileInvoice .mobileInvoiceInfo .date {
  border-left: 1px solid rgba(234, 236, 240, 1);
  border-right: 1px solid rgba(234, 236, 240, 1);
  flex-grow: 3;
  text-align: center;
}

.mobileInvoice .mobileInvoiceInfo .status {
  text-align: end;
}

.mobileInvoiceInfo .status .paid {
  color: rgba(2, 122, 72, 1);
  background: rgba(236, 253, 243, 1);
}

.mobileInvoiceInfo .status .void {
  color: #344054;
  background: #f2f4f7;
}

.mobileInvoiceInfo .status .unpaid {
  color: #c01048;
  background: #fff1f3;
}

.mobileInvoiceInfo .status .draft {
  color: rgba(52, 64, 84, 1);
  background: rgba(242, 244, 247, 1);
}

.mobileInvoiceBox {
  display: none;
}

.mobileMyInv {
  display: block;
}

.mobilePagination {
  display: none;
}

@media (max-width: 800px) {
  .mobileInvoiceBox {
    display: block;
  }

  .mobileMyInv {
    display: none;
  }

  .mobilePagination {
    display: block;
    background-color: #fff;
    margin-top: 16px;
  }

  .mobileInvoiceBox .topLine {
    border-bottom: none;
    padding: 0px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .mobileInvoiceBox .topLine .memberMobileSearch {
    width: 100%;
    display: none;
  }

  .mobileInvoiceBox .topLine .memberMobileSearch .searchInput {
    margin-right: 0px;
  }

  .mobileInvoiceBox .topLine .memberMobileSearch input {
    margin-top: 17px;
    width: 100%;
    height: 44px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .mobileResource .resourceBookingModal {
    margin: 0px;
  }

  .mobileResource .resourceBookingModal .modal-content {
    min-height: 100vh;
    background-color: #f7f8fa;
    border: none;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
  }

  .mobileResource .resourceBookingModal .modal-content .closeModal {
    display: none;
  }

  .mobileResource .resourceBookingModal .addMemberForm {
    background-color: #f7f8fa;
  }

  .mobileResource .resourceBookingModal .addMemberForm .container {
    background-color: #fff;
    padding: 20px 16px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
  }
  .mobileInvoice .mobileInvoiceInfo .date {
    border-left: none;
    border-right: none;
  }
}
