.mobileFile {
  border: 1px solid rgba(234, 236, 240, 1);
  background-color: #fff;
  padding: 16px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.mobileFile .fileBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobileFile .filesDetails {
  width: 100%;
  padding: 0px 10px;
}

.mobileFile .filesDetails h5 {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
  margin-bottom: 0px;
  cursor: pointer;
}

.mobileFile .filesDetails p {
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);
}

.mobileFile .filesDetails p .date {
  padding-right: 12px;
  border-right: 1px solid rgba(234, 236, 240, 1);
}

.mobileFile .filesDetails p .fileSize {
  padding-left: 10px;
}

.mobileFile .filesShareBtn {
  display: flex;
  justify-content: end;
}

.mobileFilesHeader {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobileFilesModal .modal-dialog {
  margin: 0px;
}

.mobileFilesModal .modal-content {
  min-height: 100vh;
  overflow-y: scroll;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.filesHeading .icon {
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.mobileFilesModal .filesHeading,
.mobileResource .filesHeading {
  display: flex;
  justify-content: space-between;
  background: rgba(31, 41, 55, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 24px;
  margin: 0;
  padding: 16px 20px;
}

.mobileFilesModal .filesContent {
  padding: 20px;
}

.mobileFilesHeader input {
  border: none;
  height: 44px;
  box-shadow: 0px 1px 1px 0px rgba(16, 24, 40, 0.09);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.mobileFilesHeader .uploadIcon {
  background: rgba(99, 102, 241, 1);
  box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
  color: white;
  border: none;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.mobileFilesHeader .searchInput svg {
  color: rgba(102, 112, 133, 1);
}

.tableHeading {
  display: block;
}

.memberSearch {
  display: flex;

}

.mobileFilesList {
  display: none;
}

.pcFilesList {
  display: block;
}

.mobileFilesModal .filesContent {
  height: calc(100vh - 167px);
  overflow-y: auto;
}

.myFilesTable .memberSearch .searchInput {
  margin-right: 15px !important;
}
.mobileResource .filesHeading{
  display: none;
}
@media (max-width: 800px) {
  
  .myFilesTable .topLine {
    padding: 0px;
  }

  .myFilesTable {
    background-color: transparent;
  }

  .mobileFilesHeader {
    display: flex;
  }

  .mobileFilesHeader .taskDropdown .dropdown button,
  .mobileFilesHeader .uploadIcon {
    width: 44px !important;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobileFilesHeader .uploadIcon svg {
    margin-right: 0px;
  }

  .memberSearch {
    display: none;
  }

  .pcFilesList {
    display: none;
  }

  .mobileFilesList {
    display: block;
    width: 100%;
  }

  .drop-file-input {
    background-color: #f2f4f7 !important;
    width: 100% !important;
  }
}