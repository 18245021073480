:root {
  --theme-color: rgba(255, 204, 2, 1);
  --theme-heading: "Asset, sans-serif";
  --theme-paragraph: "Asset, sans-serif";
  --theme-body: rgba(255, 204, 2, 1);
}

.landingPage {
  background-color: rgba(245, 245, 245, 1);
}
.topHeader {
  min-height: 52px;
  background-color: rgba(25, 25, 25, 1);
  padding: 14px 0px;
}
.contactInfo a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 32px;
  font-family: var(--theme-paragraph) !important;
}
.contactInfoIcon a {
  text-decoration: none;
  background-color: #fff;
  color: rgba(25, 25, 25, 1);
  font-size: 14px;
  font-weight: 700;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.landingNavbar {
  min-height: 130px;
}
.landingNavbar .navbarMiddle a {
  font-family: var(--theme-heading) !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 25, 25, 1);
  text-decoration: none;
}
.landingNavbar .loginBtn {
  border: 1px solid rgba(25, 25, 25, 1);
  font-family: var(--theme-heading) !important;
  color: rgba(25, 25, 25, 1);
  padding: 7px 18px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.landingNavbar .bookATour {
  border: 1px solid var(--theme-color);
  font-family: var(--theme-heading) !important;
  font-weight: 600;
  text-decoration: none;
  color: rgba(25, 25, 25, 1);
  background-color: var(--theme-color);
  padding: 7px 18px;
  margin-left: 16px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
/* Header */

.landingHeading h2 {
  font-family: var(--theme-heading) !important;
  font-size: 36px;
  font-weight: 600;
  line-height: 30px;
  padding-left: 12px;
  text-align: left;
  border-left: 4px solid var(--theme-color);
  margin-bottom: 0px;
}
/* banner */
.landingImage {
  min-height: 400px;
  /* background-image: url("../../Assets/Images/background/memberSignUp.png"); */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.landingImage h1 {
  font-family: var(--theme-heading) !important;
  font-size: 36px;
  font-weight: 500;
  line-height: 43.21px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
}
.landingImage p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: var(--theme-paragraph) !important;
}
.meetBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: auto;
  justify-content: center;
  align-content: start;
  align-items: start;
}
.meetBox p {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: rgba(68, 68, 68, 1);
  font-family: var(--theme-paragraph) !important;
}
.meetImage img {
  max-height: 430px;
  box-shadow: 20px -20px 0px 0px var(--theme-color);
  width: 100%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
/* why hive */
.hiveCard {
  margin-top: 20px;
  padding: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.hiveCard p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: rgba(25, 25, 25, 1);
  font-family: var(--theme-paragraph) !important;
}
/* Available card */
.availableCard img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.availableCard .spacesRate {
  margin-top: 24px;
  border-left: 4px solid var(--theme-color);
  padding-left: 12px;
}
.availableCard .spacesRate h6 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: rgba(25, 25, 25, 1);
  margin-bottom: 2px;
}

.availableCard .spacesRate p,
.availableCard .spacesRate h6 span {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: rgba(123, 123, 123, 1);
  margin-bottom: 0px;
  font-family: var(--theme-paragraph) !important;
}
.bookSpaces {
  border: 1px solid #191919;
  font-family: var(--theme-heading) !important;
  font-weight: 600;
  text-decoration: none;
  color: rgba(25, 25, 25, 1);
  background-color: transparent;
  padding: 7px 18px;
  margin-left: 16px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
/* community card */
.community {
  padding: 60px 0px;
}
.communityCard {
  background-color: #fff;
  border: 1px solid rgba(233, 232, 228, 1);
  padding: 32px 24px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.communityCard .communityHeading {
  display: flex;
  align-items: center;
}
.communityCard .communityHeading img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.communityCard .communityHeading h6 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  margin-bottom: 2px;
  font-family: var(--theme-heading) !important;
}
.communityCard .communityHeading p {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: rgba(68, 68, 68, 1);
  margin-bottom: 0px;
  font-family: var(--theme-paragraph) !important;
}
.communityCard .communityContent p {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  font-family: var(--theme-paragraph) !important;
}
.randomImage img {
  width: 100%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
/* resources */
.checkAvailable {
  border: 1px solid var(--theme-color);
  font-family: var(--theme-heading) !important;
  font-weight: 600;
  text-decoration: none;
  color: rgba(25, 25, 25, 1);
  background-color: var(--theme-color);
  padding: 7px 18px;
  margin-left: 16px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
/* faq */
.faqContent {
  margin-top: 25px;
  padding-right: 35px;
}

.faqContent .accordion-header,
.faqContent .accordion-item,
.faqContent button {
  background-color: transparent !important;
  background: transparent !important;
  border: none;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.faqContent button {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: rgba(25, 25, 25, 1) !important;
  padding: 30px 0px;
  transition: none !important;
  transform: none !important;
  box-shadow: none !important;
  border: none;
}
.faqContent .accordion-item {
  border-bottom: 1px solid #191919;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}
.faqContent .accordion-button::after {
  content: "+";
  font-weight: 600;
  background-image: none !important;
  transition: none !important;
  transform: none !important;
  display: flex;
  align-items: center;
  margin-top: -6px;
  margin-right: 7px;
  font-size: 25px;
  color: var(--theme-color);
}
.faqContent .accordion-button:not(.collapsed)::after {
  content: "x";
  font-weight: 600;
  font-size: 20px;
  margin-right: 4px;
  background-image: none !important;
  transition: none !important;
  transform: none !important;
  margin-top: -7px;
}
.faqImage img {
  width: 100%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.testimonialHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slideImg {
  padding-right: 15px;
}
.slideImg img {
  width: 100%;
  object-fit: cover;
}

/* Contact */
.contactBox {
  background: rgba(249, 250, 251, 1);
  padding: 64px !important;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.contactInput {
  margin-top: 20px;
}
.contactInput label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: rgba(25, 25, 25, 1);
  display: block;
  margin-bottom: 12px;
  font-family: var(--theme-paragraph) !important;
}
.contactInput input,
.contactInput input:focus,
.contactInput input:focus-visible {
  border: 1px solid rgba(241, 243, 247, 1) !important;
  box-shadow: none !important;
  color: rgba(109, 117, 143, 1);
  font-weight: 600;
  padding: 14px 16px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.contactInput textarea,
.contactInput textarea:focus,
.contactInput textarea:focus-visible {
  border: 1px solid rgba(241, 243, 247, 1) !important;
  min-height: 90px;
  box-shadow: none !important;
  color: rgba(109, 117, 143, 1);
  font-weight: 600;
  padding: 14px 16px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.contactMap {
  height: 100%;
}
.contactMap div,
.contactMap div iframe {
  width: 100% !important;
  height: 100% !important;
  box-shadow: 20px -20px 0px 0px var(--theme-color);
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
/* Footer */
.footerBox {
  background: rgba(25, 25, 25, 1);
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  padding: 40px 80px !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.copyRights {
  border-top: 1px solid rgba(245, 245, 245, 1);
  background: rgba(25, 25, 25, 1);
  padding: 32px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.copyRights p,
.copyRights a {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0px;
  text-decoration: none;
  font-family: var(--theme-paragraph) !important;
}
.footerDescription p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0px;
  font-family: var(--theme-paragraph) !important;
}
.footerFollowUs {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.footerFollowUs h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  text-align: end;
  font-family: var(--theme-heading) !important;
}
.footerInfoIcon a {
  text-decoration: none;
  background-color: var(--theme-color);
  color: rgba(25, 25, 25, 1);
  font-size: 14px;
  font-weight: 700;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.poweredByFooter {
  padding: 38px;
}
.poweredByFooter p {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 25, 25, 1);
  margin-bottom: 0px;
  font-family: var(--theme-paragraph) !important;
}
.poweredByFooter p a {
  color: rgba(99, 102, 241, 1);
  text-decoration: none;
  font-family: var(--theme-paragraph) !important;
}
