.publicAssets {
  background-color: #fff;
  width: 850px;
  padding: 32px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.publicMember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}

.publicMember p {
  font-family: "MyCustomFont";
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: rgba(29, 41, 57, 1);
  margin-bottom: 0px;
}

.publicMember a,
.publicMember a:hover {
  border: 1px solid rgba(99, 102, 241, 1);
  color: rgba(99, 102, 241, 1);
  text-decoration: none;
  padding: 14px 18px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.publicAsset {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgba(234, 236, 240, 1);
  width: 100%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.publicAsset .assetImage {
  display: flex;
  align-items: center;
}

.publicAsset .assetImage img {
  margin-right: 36px;
  width: 52px;
  height: 52px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.publicAsset .assetImage div p {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: rgba(78, 93, 120, 1);
}

.publicAsset .assetImage div span {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: rgba(176, 183, 195, 1);
}

.publicAsset .assetBtnInfo {
  display: flex;
  align-items: center;
}

.publicAsset .assetBtnInfo button {
  margin-left: 35px;
  background: #6366f1;
  padding: 11px 22px;
  border: none;
  font-family: "MyCustomFont";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  text-decoration: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.mobileAssets .deskType {
  display: none;
}

.mobileAssets .viewResourceBtn {
  background-color: transparent;
  border: none;
  color: #4e5d78;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
  text-align: left;
}

.mobileAssetsNotes {
  display: none;
}

@media (max-width: 800px) {
  .publicAssets {
    width: 100%;
    padding: 20px;
  }

  .publicMember {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    padding-bottom: 16px;
  }

  .publicMember a {
    margin-top: 15px;
    width: 100%;
    padding: 10px 12px;
  }

  .publicAsset,
  .publicAsset .assetImage {
    flex-direction: column;
    width: 100%;
  }

  .publicAsset .assetImage img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    margin-right: 0px;
  }

  .mobileAssets {
    margin-top: 16px;
    justify-content: start;
    width: 100%;
  }

  .mobileAssets p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }

  .mobileAssets .deskType {
    display: block;
  }

  .assetBtnInfo .deskType {
    display: none;
  }

  .assetBtnInfo {
    width: 100%;
  }

  .assetBtnInfo .mobileAssetsBtn {
    margin-top: 15px;
    margin-left: 0px !important;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .publicAssets .topLine {
    padding: 0px;
    margin-top: 15px;
  }

  .publicAssets .topLine .editBtn {
    display: none;
  }

  .publicAssets .spacesInfo {
    padding: 0px;
    flex-direction: column-reverse;
    margin-top: 15px;
  }

  .publicAssets .spacesInfo .rightSpacesContent,
  .publicAssets .spacesInfo .leftSpacesImage {
    width: 100%;
  }

  .publicAssets .spacesInfo .rightSpacesContent {
    margin-top: 16px;
  }

  .resourceMiniBox .resourceRate,
  .memberMiniBox .resourceName,
  .resourceMiniBox .resourceName {
    padding: 16px 20px;
  }
  .mobileAssetsNotes {
    display: flex;
  }
  .mobileAssetsNotes a {
    margin-top: 20px;
    width: 100%;
    background: #6366f1;
    padding: 12px 16px;
    border: none;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-decoration: none;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
