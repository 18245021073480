#delete-confirm .modal-content {
    width: 440px;
    margin-left: auto;
    margin-right: auto;
}

#delete-confirm .addMemberForm {
    padding: 24px;
}

.deleteIcon {
    text-align: center;
}

.deleteIcon img {
    background-color: rgba(255, 241, 243, 1);
    padding: 12px;
    font-size: 47px;
    color: rgba(192, 16, 72, 1);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.deleteIcon h4 {
    margin-top: 16px;
    font-family: "MyCustomFont";
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    color: #1D2939;
}

.deleteIcon p {
    margin-top: 8px;
    margin-bottom: 2rem;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #475467;
}

.deleteBtn {
    display: flex;
    justify-content: center;
}

.deleteBtn button {
    margin: 0 7px;
    color: white;
    min-width: 188px;
    padding: 12px 18px;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    border: none;
    box-shadow: 0px 6px 30px 0px rgba(42, 55, 119, 0.06);
    border-radius: 12px !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.deleteBtn .cancel {
    color: #6366F1;
    background-color: white;
    border: 1px solid #6366F1;
}

.deleteBtn .delete {
    background: #F04438;
}

.pcDeleteModal {
    display: block;
}

.mobileDeleteModal {
    display: none;
}

@media (max-width: 800px) {
    .pcDeleteModal {
        display: none;
    }

    .mobileDeleteModal {
        display: block;
    }

    #delete-confirm .modal-content {
        width: 100%;
    }

    .mobileDeleteModal .deleteHeading {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(234, 236, 240, 1);
        display: flex;
        align-items: center;
    }

    .mobileDeleteModal .deleteHeading img {
        margin-right: 16px;
        background: rgba(254, 243, 242, 1);
        padding: 15px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }

    .mobileDeleteModal .deleteHeading p {
        font-family: "MyCustomFont";
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: rgba(29, 41, 57, 1);
        margin-bottom: 0px;
    }

    .mobileDeleteModal .deleteContent {
        padding: 20px 0px;
        text-align: center;
    }

    .mobileDeleteModal .deleteContent p {
        font-family: "MyCustomFont";
        font-size: 14px;
        font-weight: 350;
        line-height: 24px;
        text-align: center;
        color: rgba(71, 84, 103, 1);
        margin-bottom: 0px;
    }

    .mobileDeleteModal .deleteModalBtn {
        display: flex;
        justify-content: space-between;
    }

    .mobileDeleteModal .deleteModalBtn button {
        width: 45%;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 14px 18px;
        font-family: "MyCustomFont";
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }

    .mobileDeleteModal .deleteModalBtn button.cancel {
        border: 1px solid rgba(99, 102, 241, 1);
        background-color: #fff;
        color: rgba(99, 102, 241, 1);
    }

    .mobileDeleteModal .deleteModalBtn button.delete {
        border: 1px solid rgba(240, 68, 56, 1);
        background: rgba(240, 68, 56, 1);
        color: rgb(255, 255, 255);
    }
}