.billingList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 225px);
}

.invoiceHeading {
    margin-top: -7px;
    margin-bottom: 25px;
}

.invoiceHeading .breadcrumb .breadcrumb-item {
    padding-left: 16px;
}

.invoiceHeading .breadcrumb .breadcrumb-item,
.invoiceHeading .breadcrumb .breadcrumb-item a {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    text-decoration: none;
}

.invoiceHeading .breadcrumb .breadcrumb-item::before,
.invoiceHeading .breadcrumb .breadcrumb-item.active::before {
    color: #D0D5DD;
    width: 24px;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
}

.invoiceHeading .breadcrumb .breadcrumb-item.active {
    font-family: "MyCustomFont";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--Primary-700, #3D3FA1);
    font-style: normal;
    font-weight: 800;
    padding-left: 16px;
}

.billingList .table-responsive {
    width: 100%;
}

.billingList table thead tr th {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #475467;
    height: 56px;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #F4F7F8;
}

.billingList table tbody tr td {
    height: 72px;
    text-align: left;
}

.billingList table thead tr th:nth-child(1),
.billingList table tbody tr td:nth-child(1) {
    width: 68px;
    text-align: center;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table thead tr th:nth-child(2),
.billingList table tbody tr td:nth-child(2) {
    width: 119px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table tbody tr td:nth-child(2) a {
    text-decoration: none;
    color: var(--Gray-900, #101828);
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
}

.billingList table thead tr th:nth-child(3),
.billingList table tbody tr td:nth-child(3) {
    width: 210px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table thead tr th:nth-child(4),
.billingList table tbody tr td:nth-child(4) {
    width: 186px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table thead tr th:nth-child(5),
.billingList table tbody tr td:nth-child(5) {
    width: 170px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table tbody tr td:nth-child(5) {
    color: rgba(71, 84, 103, 0.7) !important;
}

.billingList table thead tr th:nth-child(6),
.billingList table tbody tr td:nth-child(6) {
    width: 124px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table thead tr th:nth-child(7),
.billingList table tbody tr td:nth-child(7) {
    width: 119px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table thead tr th:nth-child(8),
.billingList table tbody tr td:nth-child(8) {
    width: 99px;
    padding: 0px !important;
    position: relative;
    vertical-align: middle;
}

.billingList table tbody tr td:nth-child(2) {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(16, 24, 40, 1);
}

.billingList table tbody tr td:nth-child(3),
.billingList table tbody tr td:nth-child(4),
.billingList table tbody tr td:nth-child(5),
.billingList table tbody tr td:nth-child(7) {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
}

.billingList table tbody tr td:nth-child(3) img,
.billingList table tbody tr td:nth-child(4) img,
.billingList table tbody tr td:nth-child(5) img {
    margin-right: 8px;
}

.billingList .status span {
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    width: auto;
    padding: 5px 10px;
    margin-bottom: 0px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.billingList .status .paid {
    color: rgba(2, 122, 72, 1);
    background: rgba(236, 253, 243, 1);
}

.billingList .status .void {
    color: #344054;
    background: #F2F4F7;
}

.billingList .status .unpaid {
    color: #C01048;
    background: #FFF1F3;
}

.billingList .status .draft {
    color: rgba(52, 64, 84, 1);
    background: rgba(242, 244, 247, 1);
}

.billingAction .download,
.billingAction .download:hover {
    background: rgb(255, 255, 255);
    border: 1px solid rgba(99, 102, 241, 1)
}

.billingAction .view,
.billingAction .view:hover {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background: rgba(99, 102, 241, 1);

}

.ml-2 {
    margin-left: 5px;
}

.addMemberForm .memberSpaces .memberInfos {
    width: calc((100% - 60px) / 2);
}

.addMemberForm .memberSpaces .memberInfos .dropdown-content {
    padding: 20px 8px;
    height: inherit;
}

.addMemberForm .memberSpaces .memberInfos .dropdown-content .assignInput svg {
    left: 20px;
}

.addMemberForm .memberSpaces .memberInfos .dropdown-content .member-container {
    padding-left: 10px;
    padding-right: 8px;
}

.memberInfos {
    display: flex;
    align-items: center;
}

.memberInfos p {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
    margin-bottom: 0px;
}

.memberInfos span {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(152, 162, 179, 1);
}

.memberInfos img {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.invoiceMemberInfo h1 {
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(29, 41, 57, 1);
}

.filterDropdown button {
    background-color: #fff;
    padding: 0px;
    border: none;
    font-family: 'MyCustomFont';
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    text-decoration: none;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.filterDropdown button .filterBtn {
    background-color: #fff;
    padding: 12px 16px;
    text-transform: capitalize;
}

.filterDropdown .dropdown-toggle::after {
    display: none;
}

.filterDropdown .btn-check:checked+.btn,
.filterDropdown .btn.active,
.filterDropdown .btn.show,
.filterDropdown .btn:first-child:active,
.filterDropdown :not(.btn-check)+.btn:active {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.filterDropdown .btn:hover {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.filterDropdown .dropdown-menu a {
    background-color: #fff;
    font-family: "MyCustomFont";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(52, 64, 84, 1);
}

.filterDropdown .dropdown-menu {
    min-width: 135px !important;
}

#invoice-detail .topLine,
#my-billing .topLine,
#billing .topLine {
    /* height: 85px; */
}

#my-billing .dropdown-menu,
#billing .dropdown-menu {
    /* display: flex; */
    padding: 18px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: var(--Base-White, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(122, 122, 122, 0.14);
    border: none;
}

#my-billing .filterBtn,
#billing .filterBtn {
    width: 100px;
}

#my-billing .filterBtn img,
#billing .filterBtn img {
    margin-left: auto;
}

.cardNameAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardNameAmount .resourceInput:nth-child(1) {
    width: 79%;
}

.cardNameAmount .resourceInput:nth-child(2) {
    width: 19%;
}

.invoiceSpacesList {
    display: flex;
    align-items: center;
    justify-content: left;
}

.invoiceSpacesList img {
    margin-left: -12px;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
}

.invoiceSpacesList img:nth-child(1) {
    margin-left: 0px !important;
}

.mobileBillHeading {
    display: none;
}



.customCheckbox-wrapper {
    display: flex;
    margin-top: 15px;
}

.customCheckbox-wrapper label {
    margin-left: 10px;
    cursor: pointer;
}

.customCheckbox-wrapper input {
    cursor: pointer;
}
.customCheckbox input {
    opacity: 0;
    width: 20px;
}

.customCheckbox input:checked~.checkmark {
    background-color: #E0E0FC;
    border-color: #5052C9;
}

.customCheckbox input:checked~.checkmark:after {
    display: block;
}
.customCheckbox input~.checkmark:after {
    display: none;
}

.customCheckbox {
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.customCheckbox .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
    width: 20px;
    border: 1px solid #cbcbcb;
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

.customCheckbox .checkmark::after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #6366F1;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
}




@media screen and (max-width: 1087px) {
    .manual-invoice {
        margin-top: 16px;
    }
}

@media screen and (max-width: 800px) {
    .mobileBilling {
        flex-direction: column;
    }

    .cardNameAmount .resourceInput:nth-child(1) {
        width: 100%;
    }

    .cardNameAmount .billingAmount {
        display: none;
    }

    .mobileBilling .addMemberForm {
        background-color: #F7F8FA;
        padding: 22px 20px;
    }

    .mobileBilling .addMemberForm .resourcePayment {
        padding: 16px;
        background-color: #fff;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }

    .mobileBillHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobileBillHeading p {
        font-family: 'MyCustomFont';
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: rgba(16, 24, 40, 1);
    }

    .mobileBillHeading p.amount {
        color: rgba(185, 56, 21, 1);
    }

    .resourcePayHeading h6 {
        display: none;
    }

    .memberAddBtn button {
        width: 100%;
        background: rgba(18, 183, 106, 1);
    }

    .filterBtn img {
        width: 16px;
        margin-right: 0;
    }

    .taskDropdown .filterBtn {
        display: flex;
        gap: 5px;
        padding: 9px 14px;
    }

    .mobileFilesHeader .uploadIcon {
        padding: 15px 18px !important;
    }

    .mobileFilesHeader.mb-3 {
        gap: 5px
    }

    .mobileFilesModal .filesContent {
        height: calc(100vh - 205px) !important;
    }

    .paginationDropdown .dropdown-menu,
    .filterDropdown.taskDropdown .dropdown-menu {
        margin-top: 0 !important;
    }

    .filterDropdown button .filterBtn {
        width: auto;
    }


}